import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  message,
  Typography,
  Row,
  Col,
  Modal,
  Divider,
  Upload,
  Avatar,
  Spin,
} from "antd";
import moment, { Moment } from "moment";
import { fetchUserData } from "../../../services/userApis/userApis";
import { UserData } from "../../types";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useUpdateEmployeeProfileMutation, useUserProfileImageMutation } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { RcFile } from "antd/es/upload";


const { Title } = Typography;
interface EditProfileProps {
  openModal?: boolean;
  closeModal?: () => void;
  userData?: UserData | null;
  onModalClose: () => void;
  fetchUserData?: () => void;
}

export const EditProfile: React.FC<EditProfileProps> = ({
  openModal,
  closeModal,
  userData,
  onModalClose,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(openModal ? true : false);
  const [user, setUser] = useState<UserData | null>(userData ? userData : null);
  const [isModalOpen, setIsModalOpen] = useState(openModal ? true : false);
  const [ding, setding] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState<string>("");
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState("");
  const [cnicValue, setCnicValue] = useState("");
  const [dob, setDob] = useState("");
  const [userdata, setUserdata] = useState<UserData | null>(null);
  const [updateEmployeeProfile, { isLoading: isUpdating, }] = useUpdateEmployeeProfileMutation();
  const { userProfile } = useSelector((state: RootState) => state.auth);
  const [userProfileImage, { isLoading: isImageUploading }] = useUserProfileImageMutation();
  useEffect(() => {
    if (userProfile) {
      setUserdata(userProfile);
    }
  }, [userProfile]);
  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        ...userData,
        dob: userData.dob
          ? moment(userData.dob).format("YYYY-MM-DD")
          : "",
        joiningDate: moment(userData.joiningDate),
      });
      setProfileImage(
        userData.profileImage || "/assets/menIcon.jpg"
      );
      // const phoneNumber = userData.userDetail.phone;
      // if (phoneNumber) {
      //   // Assuming phoneNumber is just digits; prepend a country code as needed
      //   // For example, adding '+1' for US numbers
      //   const formattedPhone = `${phoneNumber}`;
      //   console.log(formattedPhone);
      //   console.log(phone);
      //   setPhone(formattedPhone);
      // }
    }
  }, [userData, form]);

  const handleUploadSuccess = async (info: any) => {
    if (info.file.status === "done") {
      const response = info.file.response;
      setImageUrl(response.url);
    }
  };
  const handlePhoneChange = (value: any) => {
    userData!.phone = value;
    setPhone(value);
  };
  const handleChange = (value: string) => {
    setSelectedDesignation(value);
  };

  const onFinish = async (values: any) => {
    setding(true);
    setError(null);
    const payload = {
      name: values.name || userData?.name,
      fatherName: values.fatherName || userData?.fatherName,
      email: values.personalEmail || userData?.email,
      address: values.address || userData?.address,
      phone: values.phone || userData?.phone,
      dob: values.dob ? moment(values.dob).toISOString() : userData?.dob,
      cnic: values.cnic || userData?.cnic,
      gender: values.gender || userData?.gender,
      profileImage: imageUrl || profileImage,
    };

    try {
      await updateEmployeeProfile({ employeeId: userdata?._id, credentials: payload }).unwrap();

      message.success("Profile Updated Successfully!");
      if (closeModal) {
        closeModal();
      }
      if (onModalClose) onModalClose();
    } catch (err) {
      console.error("Failed to save user:", err);
      message.error("Failed to save user. Please try again.");
    } finally {
      setding(false);
      setLoading(false);
    }
  };
  const handleUpload = async (file: RcFile) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      setding(true);
      const response = await userProfileImage(formData).unwrap();
      setImageUrl(response.url);
      message.success("Image uploaded successfully!");
    } catch (error) {
      message.error("Image upload failed!");
    } finally {
      setding(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    if (userProfile) {
      // fetchUserData();
    }
    if (closeModal) {
      closeModal();
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, "");

    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }
    setValue(inputValue);
  };
  useEffect(() => {
    console.log("value", value);
  }, [value]);
  const formatCNIC = (value: string): string => {
    // Remove non-digit characters
    value = value.replace(/\D/g, "");

    // Format the value
    if (value.length <= 5) {
      return value;
    } else if (value.length <= 12) {
      return `${value.slice(0, 5)}-${value.slice(5)}`;
    } else {
      return `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(
        12,
        13
      )}`;
    }
  };
  const handleCnicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCNIC(e.target.value);
    setCnicValue(formattedValue);
    form.setFieldsValue({ cnic: formattedValue });
  };
  return (
    <div>
      <Modal
        title="Edit Profile"
        visible={isModalOpen}
        onCancel={handleCancel}
        className="!w-[70%]"
        confirmLoading={ding}
        footer={[
          <div className="footerContainer">
            {isUpdating && <Spin
              indicator={<Spin style={{ fontSize: 24 }} />}
              size="large"
            />}
            <Button
              key="cancel"
              onClick={handleCancel}
              className="footerButton"
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              onClick={() => form.submit()}
              className="footerButton"
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Divider className="!mt-3" />

        <Row gutter={[16, 0]}>
          <Col xs={8} lg={6} style={{ marginTop: 20 }}>
            <Form.Item name="profileImage">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={imageUrl || profileImage}
                  alt="User Profile"
                  style={{
                    width: 140,
                    height: 140,
                    marginTop: 10,
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <Upload
                  name="file"
                  showUploadList={false}
                  beforeUpload={handleUpload}
                  accept="image/*"
                >
                  <Button icon={<UploadOutlined />}>Upload Profile Image</Button>
                </Upload>
                {isImageUploading && <Spin
                  indicator={<Spin style={{ fontSize: 24 }} />}
                  size="large"
                />}
              </div>
            </Form.Item>
          </Col>

          <Col xs={8} sm={2} md={16} lg={18}>
            <div
              className="modalBody"
              style={{
                maxHeight: "60vh",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              <div className="form-container">
                <Form
                  form={form}
                  className="mt-4 w-full"
                  name="basic"
                  labelCol={{ span: 8 }}
                  initialValues={{
                    remember: true,
                    dob: user?.dob
                      ? moment(user.dob)
                      : null,
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}></Col>
                  <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                    <Title level={5} className="ml-2">
                      User Details
                    </Title>
                  </div>

                  <Row gutter={[16, 0]}>
                    {" "}
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>Name
                          </span>
                        }
                        name="name"
                      >
                        <Input placeholder="Enter full name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Father Name" name="fatherName">
                        <Input placeholder="Enter father name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Personal Email
                          </span>
                        }
                        name="personalEmail"
                      >
                        <Input type="email" placeholder="Enter email" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Address" name="address">
                        <Input placeholder="Enter address" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <pre>{phone}</pre>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Phone Number
                          </span>
                        }
                        labelCol={{ span: 10 }}
                        name="phone"
                      >
                        <PhoneInput
                          inputStyle={{ width: "100%", height: "32px" }}
                          containerStyle={{ width: "100%", height: "32px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>CNIC
                          </span>
                        }
                        name="cnic"
                      >
                        <Input
                          placeholder="XXXXX-XXXXXXX-X"
                          value={cnicValue}
                          onChange={handleCnicChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Gender
                          </span>
                        }
                        labelCol={{ span: 12 }}
                        name="gender"
                      >
                        <Select
                          defaultValue="Select Gender"
                          onChange={handleChange}
                          options={[
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> DOB
                          </span>
                        }
                        name="dob"
                      >
                        <Input
                          type="date"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />

                        {/* {user && openModal ? (
                          <Input
                            defaultValue={moment(user.userDetail.dob).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        ) : (
                          <DatePicker style={{ width: "100%" }} />
                        )} */}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
