import { Button, Modal, Form, Input, message } from "antd";
import { FaKey } from "react-icons/fa6";
import axios from "axios";
import { Typography, Divider, Row, Avatar, Col, Table, Spin } from "antd";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../../services/userApis/userApis";
import { UserData, AttendanceData, User } from "../../types";
import { fetchUserAttendance } from "../../../services/attendenceApis/attendence";
import { format } from "date-fns";
import { EditProfile } from "./EditUserProfile";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useChangePasswordMutation, useGetEmployeeAttendanceQuery } from "../../../api";

export const Profile = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [attendanceData, setAttendanceData] = useState<AttendanceData[]>([]);
  const [loading, setLoading] = useState(true);

  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showimageModal = () => setVisible(true);
  const handleimageCancel = () => setVisible(false);
  const [users, setUsers] = useState<User[]>([]);
  const { userProfile } = useSelector((state: RootState) => state.auth);
  const [changePassword, { isLoading, isSuccess, isError, error }] = useChangePasswordMutation();
  const { data,} = useGetEmployeeAttendanceQuery({ employeeId: userData?._id });

  useEffect(() => {
    if (data && Array.isArray(data?.data)) {
      setAttendanceData(data?.data);
      setLoading(false);
    } else {
      setAttendanceData([]);
      setLoading(false);
    }
  }, [data]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleOpenMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = async (values: { oldPassword: string; newPassword: string }) => {
    try {
      const result = await changePassword({
        employeeId: userData?._id,
        credentials: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      }).unwrap();
      if (result?.success) {
        message.success(result.message || "Password changed successfully.");
        form.resetFields();
        handleCancel();
      } else {
        message.error(result?.message || "Failed to change the password.");
      }
    } catch (error) {
      message.error("An error occurred while changing the password.");
    }
  };
  useEffect(() => {
    if (userProfile) {
      setUserData(userProfile);
    }
  }, [userProfile]);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  const convertToHHMMSS = (decimalHours: number) => {
    if (isNaN(decimalHours)) {
      return "N/A";
    }
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const totalAttendance = attendanceData?.length;
  const totalLeaves = userData && Array.isArray(userData) ? userData : 0;

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "PRESENT":
        return "green";
      case "ABSENT":
        return "red";
      case "HALF_DAY":
        return "Blue";
      case "lEAVE":
        return "orange";
      default:
        return "gray";
    }
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => format(new Date(date), "PPP"),
    },
    {
      title: "CheckedIn",
      dataIndex: "time_in",
      key: "time_in",
      render: (time_in: string) => format(new Date(time_in), "hh:mm a"),
    },
    {
      title: "CheckedOut",
      dataIndex: "time_out",
      key: "time_out",
      render: (time_out: string) => {
        if (!time_out || isNaN(Date.parse(time_out))) {
          return "N/A";
        }
        return format(new Date(time_out), "hh:mm a");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span
          style={{
            backgroundColor: getStatusColor(status),
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline-block",
          }}
        >
          {status}
        </span>
      ),
    },
    {
      title: "Working Hours",
      dataIndex: "working_hours",
      key: "working_hours",
      render: (working_hours: number) => convertToHHMMSS(working_hours),
    },
  ];
  const refreshData = async () => {
    try {
      setUserData(userProfile);
    } catch (error) {
      console.error("Error fetching updated user data:", error);
    } finally {
    }
  };

  return (
    <div>
      <div className=" bg-slate-200 p-1 rounded-md ">
        <div className="border-l-4 border-secondary-color flex justify-between h-15 mr-2">
          <Title level={5} className="ml-2 mt-2">
            Details
          </Title>
          <div className="mt-1 flex flex-col sm:flex-row justify-center gap-2">
            <div>
              <Button
                type="primary"
                icon={<FaKey />}
                onClick={() => setIsModalOpen(true)}
                className="button-hover"
              >
                Edit Profile
              </Button>
              {isModalOpen && (
                <EditProfile
                  openModal={isModalOpen}
                  closeModal={() => setIsModalOpen(false)}
                  onModalClose={refreshData}
                  fetchUserData={fetchUserData}
                  userData={userData}
                />
              )}
            </div>
            <div>
              <Button type="primary" icon={<FaKey />} onClick={showModal}>
                Change Password
              </Button>
            </div>
          </div>
          <Modal
            title="Change Password"
            visible={isModalVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={null}
          >
            <Form form={form} layout="vertical" onFinish={handleOk}>
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                ]}
              >
                <Input.Password placeholder="Type Your Old Password 🤨" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your New Password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                ]}
              >
                <Input.Password placeholder="Type New Password 😊" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please Confirm the New Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Password do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm New Password 😉" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center lg:items-start p-6 mt-3">
        <div className="flex flex-col lg:items-center lg:items-start lg:mr-6 lg:ml-5 mb-6 lg:mb-0 text-center">
          <Avatar
            alt="User Avatar"
            src={userData?.profileImage || "/assets/menIcon.jpg"}
            style={{ width: 150, height: 150, cursor: "pointer" }}
            className="hover:scale-105 transition duration-150"
            onClick={showimageModal}
          />
          <Title level={1} style={{ marginTop: 10 }}>
            {userData?.name || "N/A"}
          </Title>
        </div>
        <div className="flex-1 lg:ml-10 lg:mt-10 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-6">
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">CNIC:</span>
              <span className="text-gray-800 flex-1">
                {userData?.cnic || "N/A"}
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">SignIn Email:</span>
              <span className="text-gray-800 flex-1">
                {userData?.email || "N/A"}
              </span>
            </div>

            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">Annual Leave Credits:</span>
              <span className="text-gray-800 flex-1">
                {userData?.annualLeaveBalance || "N/A"}
              </span>
            </div>

            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">Leave Credits:</span>
              <span className="text-gray-800 flex-1">
                {userData?.leaveBalance || "N/A"}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">Manager:</span>
              <span className="text-gray-800 flex-1">
                {userData?.manager || "N/A"}
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">Designation:</span>
              <span className="text-gray-800 flex-1">
                {userData?.designation || "N/A"}
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">
                Date of Birth:
              </span>
              <span className="text-gray-800 flex-1">
                {userData?.dob
                  ? format(new Date(userData?.dob), "PPP")
                  : "N/A"}
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-bold text-gray-600 w-36">
                Joining Date:
              </span>
              <span className="text-gray-800 flex-1">
                {userData?.joiningDate
                  ? format(new Date(userData?.joiningDate), "PPP")
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between bg-slate-200 p-1 rounded-md mt-4">
        <div className="border-l-4 border-secondary-color h-9 flex items-center">
          <Title level={5} className="ml-2">
            Attendance Detail
          </Title>
        </div>
      </div>
      <Table
        className="mt-4 overflow-x-auto mb-8"
        dataSource={attendanceData}
        columns={columns}
        bordered
      />
      <Modal
        visible={visible}
        onCancel={handleimageCancel}
        footer={null}
        width={400}
        style={{ top: "40%", transform: "translateY(-50%)" }}
        closeIcon={<CloseOutlined />}
      >
        <div className="modal-content" style={{ textAlign: "center" }}>
          <img
            src={userData?.profileImage}
            alt="User Avatar"
            style={{
              width: "300px",
              height: "300px",
              objectFit: "cover",
              display: "block",
              margin: "0 auto",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
