import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const leaveApplicationApi = createApi({
    reducerPath: 'leaveApplicationApi',
    baseQuery,
    endpoints: (builder) => ({
        createLeaveApplication: builder.mutation({
            query: ({ companyId, employeeId, leaveData }) => ({
                url: `/leave-applications/${companyId}/${employeeId}`,
                method: 'POST',
                data: leaveData,
            })
        }),
        getEmployeesLeaveAppli: builder.query({
            query: (companyId) => ({
                url: `/leave-applications/${companyId}`,
                method: 'GET',
            }),
        }),
        getEmployeeLeave: builder.query({
            query: ({companyId,employeeId}) => ({
                url: `/leave-applications/${companyId}/${employeeId}`,
                method: 'GET',
            }),
        }),
        updateLeaveStatus: builder.mutation({
            query: ({companyId,applicationId, leaveStatus, approvalReason}) => ({
                url: `/leave-applications/${companyId}/${applicationId}`,
                method: 'POST',
                data: {leaveStatus,approvalReason, }
            }),
        }),
    }),
});
export const { useCreateLeaveApplicationMutation, useGetEmployeesLeaveAppliQuery, useGetEmployeeLeaveQuery, useUpdateLeaveStatusMutation } = leaveApplicationApi;
