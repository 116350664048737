import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const leaveCreditsApi = createApi({
    reducerPath: 'leaveCreditsApi',
    baseQuery,
    endpoints: (builder) => ({
        addLeaveCredits: builder.mutation({
            query: ({ companyId, annualLeaveBalance }) => ({
                url: `/leave-credits/${companyId}`,
                method: 'POST',
                data: annualLeaveBalance,
            })
        }),
        updateUsersCredits: builder.mutation({
            query: ({ companyId }) => ({
                url: `/leave-credits/${companyId}`,
                method: 'PUT',
            })
        }),
        getLeaveCredits: builder.query({
            query: ({ companyId }) => ({
                url: `/leave-credits/${companyId}`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useAddLeaveCreditsMutation, useUpdateUsersCreditsMutation, useGetLeaveCreditsQuery } = leaveCreditsApi;
