import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery,
  endpoints: (builder) => ({
    userProfileImage: builder.mutation({
      query: (formData) => ({
        url: `/uploads/image`,
        method: 'POST',
        data: formData,
      }),
    }),
    employeeDocuments: builder.mutation({
      query: (formData) => ({
        url: `/uploads/document`,
        method: 'POST',
        data: formData,
      }),
    }),

  }),
});

export const { useUserProfileImageMutation,useEmployeeDocumentsMutation } = uploadApi;
