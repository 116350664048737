import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import { useEmployeeDocumentsMutation } from "../../../api"; 
import type { UploadProps} from "antd";

const { Dragger } = Upload;

interface AntDesignDraggerProps {
  maxFilesize?: number;
  onDrop?: (fileUrls: string[]) => void; 
}

const AntDesignDragger: React.FC<AntDesignDraggerProps> = ({
  maxFilesize = 10,
  onDrop,
}) => {
  const acceptedFiles = [
    ".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg", ".csv", ".xlsx", ".xls"
  ];
  const [uploadDocuments] = useEmployeeDocumentsMutation();

  const props: UploadProps = {
    name: "files",
    multiple: true,
    beforeUpload(file) {
      const isAccepted = acceptedFiles.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (!isAccepted) {
        message.error(`${file.name} is not a supported file type.`);
        return Upload.LIST_IGNORE;
      }
      const isWithinSize = file.size / 1024 / 1024 < maxFilesize;
      if (!isWithinSize) {
        message.error(`${file.name} exceeds the size limit.`);
        return Upload.LIST_IGNORE;
      }

      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info.file.response && info.file.response.uploads) {
          const fileUrls = info.file.response.uploads.map((file: any) => file.url);
          if (onDrop) {
            onDrop(fileUrls);
          }
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await uploadDocuments(formData).unwrap();
        onSuccess?.(response);
      } catch (error: unknown) {
        if (error instanceof Error) {
          message.error("File upload failed: " + error.message);
        } else {
          message.error("An unexpected error occurred during the upload.");
        }
        onError?.(error as any); 
      }
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text" style={{ color: "rgb(165, 165, 165)" }}>
        Drag 'n' drop some files here, or click to select files
      </p>
    </Dragger>
  );
};

export default AntDesignDragger;
