import React from "react";
import { AnnouncementData, AnnouncementFormData } from "../../types";
import {
  Modal,
  Divider,
  DatePicker,
  Typography,
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Upload,
  Spin,
} from "antd";

import moment from "moment";
import { FormInstance } from "antd/es/form";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc); 
interface AnnouncementModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  loading: boolean;
  onFinish: (values: AnnouncementFormData, fileList: any[]) => void;
  onFinishFailed: (errorInfo: any) => void;
  form: FormInstance;
  announcement?: AnnouncementData | null; 
}
interface UploadResponse {
  success: boolean;
  uploads?: {
    url: string;
    public_id: string;
  }[];
}
export const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  isModalOpen,
  handleCancel,
  loading,
  onFinish,
  onFinishFailed,
  form,
  announcement,
}) => {
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [uploading, setUploading] = React.useState<boolean>(false);

  React.useEffect(() => {
    
    if (announcement) {
      form.setFieldsValue({
        title: announcement.title,
        content: announcement.content,
        startDate: dayjs.utc(announcement.startDate).startOf('day').local(),  
        endDate: dayjs.utc(announcement.endDate).startOf('day').local(),
      });
      setFileList(announcement.imageUrls || []);
    } else {
      form.resetFields();
    }
  }, [announcement, form]);
  React.useEffect(() => {
    if (announcement) {
        form.setFieldsValue({
            title: announcement.title,
            startDate: dayjs.utc(announcement.startDate).startOf('day').local(), 
            endDate: dayjs.utc(announcement.endDate).startOf('day').local(),
        });
    } else {
        form.resetFields();
    }
}, [announcement, form]);
  const handleUploadChange = async (info: any) => {
    const { file } = info;

    if (file) {
      if (file.status === "uploading") {
        setUploading(true);
      } else if (file.status === "done") {
        setUploading(false);
        const response = file.response as UploadResponse;
        console.log(response);

        if (response && response.success && response.uploads) {
          const newUrls = response.uploads.map((upload) => {
            return upload.url;
          });
          setFileList((prevUrls) => [...prevUrls, ...newUrls]);
          message.success("Document uploaded successfully.");
          console.log(fileList);
        } else {
          message.error("Document upload failed: No response URL.");
        }
      } else if (file.status === "error") {
        setUploading(false);
        message.error("Document upload failed.");
      }
    } else {
      message.error("Invalid upload information.");
    }
  };
  const handleReset = () => {
    setFileList([]);
    setUploading(false);
    form.resetFields();
  };
  return (
    <div>
      <Modal
        title= {announcement ?"Edit Announcement":"Create Announcement"}
        visible={isModalOpen}
        onCancel={handleCancel}
        className="!w-[40%]"
        confirmLoading={loading}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              handleReset();
              handleCancel();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
           {announcement? "Update":"Make"}
          </Button>,
        ]}
      >
        <Divider className="!mt-3" />
        <Form
          form={form}
          className="mt-4 w-full"
          name="announcement"
          labelCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={(values) => onFinish(values, fileList)}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Form.Item<AnnouncementFormData>
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input the title!" }]}
              style={{ width: "100%", marginRight: "8px", marginLeft: "8px" }}
            >
              <Input placeholder="Announcement title" />
            </Form.Item>
          </Row>
          <Row gutter={[16, 0]}>
            <Form.Item<AnnouncementFormData>
              label="Content"
              name="content"
              rules={[{ required: true, message: "Please input the content!" }]}
              style={{ width: "100%", marginRight: "8px", marginLeft: "8px" }}
            >
              <Input.TextArea placeholder="Announcement content" rows={4} />
            </Form.Item>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item<AnnouncementFormData>
                label="Start Date"
                name="startDate"
                rules={[
                  { required: true, message: "Please select start date" },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<AnnouncementFormData>
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Please select end date" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Upload
              name="files"
              // action={`${process.env.REACT_APP_API_URL}/announcementupload`}
              showUploadList={false}
              onChange={handleUploadChange}
              multiple
              accept="image/jpeg"
            >
              <Button
                icon={<UploadOutlined />}
                style={{ display: loading ? "none" : "block" }}
              >
                Upload Images
              </Button>
              <Spin
                spinning={uploading}
                indicator={<Spin style={{ fontSize: 24 }} />}
                size="large"
              />
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
