import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery,
  endpoints: (builder) => ({
    addEmployee: builder.mutation({
      query: ({ companyId, credentials }) => ({
        url: `/employees/${companyId}`,
        method: 'POST',
        data: credentials,
      }),
    }),
    updateEmployee: builder.mutation({
      query: ({ employeeId, credentials }) => ({
        url: `/employees/${employeeId}`,
        method: 'PATCH',
        data: credentials,
      }),
    }),
    getEmployee: builder.query({
      query: (companyId) => ({
        url: `/employees/${companyId}`,
        method: 'GET',
      }),
    }),
    getCompanyProfile: builder.query({
      query: () => ({
        url: `/companies/profile`,
        method: 'GET',
      }),
    }),
    updateEmployeeStatus: builder.mutation({
      query: ({ _id, status }) => ({
        url: `/employees/${_id}/status`,
        method: 'PATCH',
        data: { status },
      }),
    }),
    resetEmployeePassword: builder.mutation({
      query: ({ _id, newPassword }) => ({
        url: `/employees/${_id}/reset-password`,
        method: 'POST',
        data: { newPassword },
      }),
    }),
  }),
});

export const {
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeStatusMutation,
  useGetEmployeeQuery,
  useResetEmployeePasswordMutation,
  useGetCompanyProfileQuery } = companyApi;
