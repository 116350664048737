import {
  UserOutlined,
  DashboardOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  UserSwitchOutlined,
  NotificationOutlined,
  DollarOutlined,
} from "@ant-design/icons";

export const employeeSidebar = [
  {
    key: "1",
    icon: DashboardOutlined,
    label: "Dashboard",
    path: "/employee",
  },
  {
    key: "2",
    icon: UserOutlined,
    label: "Profile",
    path: "/employee/profile",
  },
  {
    key: "3",
    icon: UploadOutlined,
    label: "Leave Applications",
    path: "/employee/leave-applications",
  },
];

export const adminSidebar = [
  {
    key: "1",
    icon: DashboardOutlined,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    key: "3",
    icon: UserSwitchOutlined,
    label: "Employees",
    path: "/dashboard/employees",
  },
  {
    key: "4",
    icon: CalendarOutlined,
    label: "Attendence Report",
    path: "/dashboard/attendence-report",
  },
  // {
  //   key: "5",
  //   icon: FieldTimeOutlined,
  //   label: "Daily Report",
  //   path: "daily-report",
  // },
  // {
  //   key: "6",
  //   icon: UserOutlined,
  //   label: "User Report",
  //   path: "user-report",
  // },
  {
    key: "7",
    icon: UploadOutlined,
    label: "Leave Applications",
    path: "/dashboard/leave-applications",
  },
  {
    key: "8",
    icon: NotificationOutlined,
    label: "Announcement",
    path: "/dashboard/announcement",
  },
  {
    key: "9",
    icon: CalendarOutlined,
    label: "Calender",
    path: "/dashboard/calender",
  },
  {
    key: "10",
    icon: DollarOutlined,
    label: "Salary",
    path: "/dashboard/salary",
  },

];
