import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Avatar } from "antd";
import NotificationIcon from "../Notification/notification";
import { useSelector } from "react-redux";
import { RootState } from '../../../store/store';
const userName = localStorage.getItem("firstName");

export const AppHeader = () => {
  const [userRole, setUserRole] = useState<string>("");
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    setUserRole(user?.userType || "")
  }, []);
  return (
    <div

      className="flex justify-between w-full items-center"
      style={{ backgroundColor: "#001529" }}
    >
      <Typography
        variant="h5"
        className="font-semibold"
        style={{ color: "white" }}
      >
        X-Time

      </Typography>


      <div className="flex w-2/14 justify-around items-center">
        {userName && (
          <div className="flex items-center space-x-5 mr-5">
            <Avatar className="text-5xl">{/* {userName.slice(0, 1)} */}</Avatar>
          </div>
        )}
      </div>
      {userRole === "company" && (
        <div className="mr-10" >
          <NotificationIcon />
        </div>
      )}
    </div>
  );
};
