import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getToken, removeToken, saveToken } from "../../utils/tokenManager";
import { IUser } from "./types";
import { getFromLocalStorage, saveToLocalStorage } from "../../utils/localStorageUtils";
import { LOCAL_STORE_VARIABLES } from "../../constants";

interface AuthState {
  token: string | null;
  user: IUser | null;
  userProfile: null;
}

// Retrieve token from localStorage
const initialToken = getToken();
const initialUser = getFromLocalStorage(LOCAL_STORE_VARIABLES.AUTH_USER);

const initialState: AuthState = {
  token: initialToken,
  user: initialUser as IUser || null, // Initialize user as null
  userProfile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      saveToken(action.payload);
    },
    clearToken(state) {
      state.token = null;
      state.user = null; // Clear user details as well
      state.userProfile = null;
      removeToken();
    },
    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      saveToLocalStorage(LOCAL_STORE_VARIABLES.AUTH_USER, action.payload)
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

export const { setToken, clearToken, setUser, setUserProfile } = authSlice.actions;

export default authSlice.reducer;
