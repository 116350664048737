import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { announcementAPi, attendanceApi, authApi, companyApi, employeeApi, holidayApi, leaveApplicationApi, leaveCreditsApi, salaryApi, uploadApi } from '../api';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer,
    [announcementAPi.reducerPath]: announcementAPi.reducer,
    [leaveApplicationApi.reducerPath]: leaveApplicationApi.reducer,
    [leaveCreditsApi.reducerPath]: leaveCreditsApi.reducer,
    [holidayApi.reducerPath]: holidayApi.reducer,
    [salaryApi.reducerPath]: salaryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(uploadApi.middleware)
      .concat(employeeApi.middleware)
      .concat(companyApi.middleware)
      .concat(announcementAPi.middleware)
      .concat(leaveApplicationApi.middleware)
      .concat(leaveCreditsApi.middleware)
      .concat(holidayApi.middleware)
      .concat(attendanceApi.middleware)
      .concat(salaryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
