
import React, { useState, useEffect, useCallback } from "react";
import { AnnouncementData, AnnouncementFormData } from "../../types";
import { DeleteAnnouncement, EditAnnouncement, fetchAllAnnouncement } from "../../../services/announcement/annoucement";
import { Table, Typography, Row, Button, Col, Spin, Form, notification, Modal, Input, Divider, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { format, subDays } from "date-fns";
import { useCreateAnnouncementMutation, useDeleteAnnouncementMutation, useEditAnnouncementMutation, useGetAnnouncementsQuery } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { AnnouncementModal } from "./Announcementmodal";
import dayjs from "dayjs";

export const Announcement: React.FC = () => {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<AnnouncementFormData | null>(null);
  const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
  const [editSelectedAnnouncement, setEditSelectedAnnouncement] = useState<AnnouncementData | null>(null);

  const { user: companyData } = useSelector((state: RootState) => state.auth);
  const [editAnnouncement]= useEditAnnouncementMutation()
  const { data: announcementData, isFetching, error: fetchError, refetch } = useGetAnnouncementsQuery(companyData?.id);
  const [createAnnouncement] = useCreateAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  const fetchAnnouncements = useCallback(async () => {
    setLoading(true);
    try {
      if (announcementData?.content) {
        setAnnouncements(announcementData.content);
      } else {
        throw new Error("No announcements available.");
      }
    } catch (error) {
      console.error("Error fetching announcement data:", error);
      setError("Failed to fetch announcements. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [announcementData]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleEdit = (record: AnnouncementData) => {
    setEditSelectedAnnouncement(record);
    setIsModalOpen(true);
    setIsReadOnlyModalOpen(false);
  };

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this announcement?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          const success = await deleteAnnouncement({companyId :companyData?.id ,id});
          if (success) {
            notification.success({
              message: "Success",
              description: "The announcement has been deleted successfully.",
            });
            await refetch()
          }
        } catch (error) {
          notification.error({
            message: "Error",
            description: "Failed to delete the announcement. Please try again.",
          });
        }
      },
    });
  };
  const handleRowClick = (record: AnnouncementFormData, columnIndex: number) => {
    if (columns[columnIndex].key !== "action") {
      setSelectedAnnouncement(record);
      setIsReadOnlyModalOpen(true);
    }
  };
  const truncateText = (text: string, wordLimit: number): string => {
    const words = text.split(" ");
    return words.length > wordLimit ? `${words.slice(0, wordLimit).join(" ")}...` : text;
  };

  const columns = [
    {
      title: "Creating Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (date ? format(new Date(date), "PPP") : "N/A"),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string) => (date ? format(new Date(date), "PPP") : "N/A"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => date ? format(subDays(new Date(date), 1), "PPP") : "N/A",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text: string) => <span>{truncateText(text, 5)}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Edit">
            <EditOutlined
              style={{ fontSize: 20, cursor: "pointer", marginRight: 8 }}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ fontSize: 20, color: "red", cursor: "pointer" }}
              onClick={() => handleDelete(record._id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const showModal = () => {
    setIsModalOpen(true);
    setLoading(true);
    form.resetFields();
    setEditSelectedAnnouncement(null);
    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditSelectedAnnouncement(null);
  };
  const onFinish = async (values: AnnouncementFormData, fileList: any[]) => {
    setLoading(true);
    try {
      const imageUrls = fileList.map((file) => file);
      const startDateUtc = dayjs(values.startDate)
      .local() 
      .startOf('day') 
      .add(1, 'day')
      .utc() 
      .format(); 

    const endDateUtc = dayjs(values.endDate)
      .local()
      .endOf('day') 
      .utc() 
      .format(); 

      const announcementData: AnnouncementFormData = {
        ...values,
        imageUrls,
        startDate: startDateUtc,
        endDate: endDateUtc,
      };
      const companyId = companyData?.id;

      if (editSelectedAnnouncement) {
        const editedData: AnnouncementData = {
          _id: editSelectedAnnouncement._id,
          title: announcementData.title,
          content: announcementData.content,
          startDate: announcementData.startDate,
          endDate: announcementData.endDate,
          imageUrls,
        };
        await editAnnouncement({ companyId,  announcementData:editedData}).unwrap();
        notification.success({
          message: "Success",
          description: "The announcement has been updated successfully.",
        });
      } else {
        await createAnnouncement({ companyId, announcementData }).unwrap();

        notification.success({
          message: "Success",
          description: "The announcement has been created successfully.",
        });
      }

      setIsModalOpen(false);
      setEditSelectedAnnouncement(null);
      await refetch()

    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to create or update the announcement. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => { };

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <div className="flex justify-between bg-slate-200 p-1 rounded-md">
        <div className="border-l-4 border-secondary-color h-9 flex items-center">
          <Title level={5} className="ml-2">Announcement</Title>
        </div>
        <Button onClick={showModal}>
          <EditOutlined /> Make Announcement
        </Button>
      </div>

      <AnnouncementModal
        form={form}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        loading={loading}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        announcement={editSelectedAnnouncement}
      />

      <Table
        dataSource={announcements}
        rowKey="_id"
        tableLayout="fixed"
        columns={columns.map((col, index) => ({
          ...col,
          onCell: (record: AnnouncementFormData) => ({
            onClick: () => handleRowClick(record, index),
          }),
        }))}
      />

      <Modal
        title="Announcement"
        visible={isReadOnlyModalOpen}
        onCancel={() => setIsReadOnlyModalOpen(false)}
        footer={null}
        style={{ minWidth: "40vw" }}
        centered
      >
        <Divider className="!mt-3" />
        {selectedAnnouncement && (
          <Form
            className="mt-4 w-full"
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="Title">
              <Input value={selectedAnnouncement.title} readOnly />
            </Form.Item>
            <Form.Item label="Content">
              <Input.TextArea
                rows={4}
                value={selectedAnnouncement.content}
                readOnly
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item label="Start Date">
                  <Input value={moment(selectedAnnouncement.startDate).format("MMMM Do YYYY")} readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="End Date">
                  <Input value={moment(selectedAnnouncement.endDate).format("MMMM Do YYYY")} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
};

