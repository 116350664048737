import React, { useState, useEffect } from "react";
import { Announcement, UserData } from "../../types";
import { message, Spin } from "antd";
import styled, { keyframes, css } from "styled-components";
import { useGetAnnouncementsQuery } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const scrollLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  background-color: yellow;
`;

const CarouselContent = styled.div<{ hasItems: boolean }>`
  display: inline-block;
  padding-left: 0; // Set default padding
  animation: ${(props) =>
    props.hasItems
      ? css`
          ${scrollLeft} 30s linear infinite
        `
      : "none"};
  white-space: nowrap;
`;

const AnnouncementItem = styled.div`
  display: inline-block;
  padding: 5px 20px;
  font-size: 18px;
  color: #333;
  white-space: nowrap;
`;

const CarouselComponent: React.FC = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [announcementsLoading, setAnnouncementsLoading] = useState(true);
  const { userProfile  } = useSelector((state: RootState) => state.auth);
  const [userData, setUserData] = useState<UserData | null>(null);
  const { data: announcementData, isFetching, error: fetchError, refetch } = useGetAnnouncementsQuery(userData?.company, {
    skip: !userData?.company,
  });

  useEffect(() => {
    if (userProfile) {
      setUserData(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      if (!announcementData) return;

      setAnnouncementsLoading(true);
      try {
        const response = announcementData;

        if (response.success) {
          const data: Announcement[] = response.content;
          const now = new Date();

          const filteredAnnouncements = data.filter((announcement) => {
            const startDate = new Date(announcement.startDate);
            const endDate = new Date(announcement.endDate);
            return now >= startDate && now <= endDate;
          });

          setAnnouncements(filteredAnnouncements);
        } else {
          console.error("Error fetching announcements:", response.message);
          message.error("Failed to load announcements");
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
        message.error("Failed to load announcements");
      } finally {
        setAnnouncementsLoading(false);
      }
    };
    if (userData?.company && !isFetching && announcementData) {
      fetchAnnouncements();
    }
  }, [userData, announcementData, isFetching]);
  if (announcements.length === 0) {
    return null; 
  }
  return (
    <CarouselContainer>
      <CarouselContent hasItems={announcements.length > 0}>
        {announcements.map((announcement) => (
          <AnnouncementItem key={announcement.id}>
            <span style={{ fontWeight: "bold" }}>{announcement.title}: </span>
            {announcement.content}
          </AnnouncementItem>
        ))}
      </CarouselContent>
    </CarouselContainer>
  );
};

export default CarouselComponent;
