import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const holidayApi = createApi({
    reducerPath: 'holidayApi',
    baseQuery,
    endpoints: (builder) => ({
        addHolidays: builder.mutation({
            query: ({ companyId, holidayData }) => ({
                url: `/holidays/${companyId}`,
                method: 'POST',
                data: holidayData,
            })
        }),
        updateHolidays: builder.mutation({
            query: ({ companyId, updatedHoliday }) => ({
                url: `/holidays/${companyId}`,
                method: 'PUT',
                data: updatedHoliday
            })
        }),
        updateHolidaysStatus: builder.mutation({
            query: ({ companyId, id, announcementCreated }) => ({
                url: `/holidays/${companyId}/${id}/status`,
                method: 'PUT',
                data: {announcementCreated}
            })
        }),
        getHolidays: builder.query({
            query: ({ companyId }) => ({
                url: `/holidays/${companyId}`,
                method: 'GET',
            }),
        }),
        deleteHoliday: builder.mutation({
            query: ({ companyId, id }) => ({
                url: `/holidays/${companyId}/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
export const {
    useAddHolidaysMutation,
    useUpdateHolidaysMutation,
    useGetHolidaysQuery,
    useDeleteHolidayMutation,
    useUpdateHolidaysStatusMutation
} = holidayApi;
