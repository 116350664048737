import { Provider } from "react-redux";
import { AppRoutes } from "./Navigation/AppRoutes";
// import {GlobalStyles} from "./FontFamily";
import "./index.css";
import { store } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <div className="app-wrapper">
        {/* <GlobalStyles/> */}
        <AppRoutes />
      </div>
    </Provider>
  );
}

export default App;
