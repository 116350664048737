import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const attendanceApi = createApi({
  reducerPath: 'attendanceApi',
  baseQuery,
  endpoints: (builder) => ({
    employeesAttendance: builder.query({
      query: (companyId) => ({
        url: `/attendance/${companyId}/employees`,
        method: 'GET',
      }),
    }),
    checkInAttendance: builder.mutation({
      query: ({ companyId, employeeId }) => ({
        url: `/attendance/${companyId}/${employeeId}/check-in`,
        method: 'POST',
      }),
    }),
    checkOutAttendance: builder.mutation({
      query: ({employeeId }) => ({
        url: `/attendance/${employeeId}/check-out`,
        method: 'POST',
      }),
    }),
    getEmployeeAttendance: builder.query({
      query: ({employeeId }) => ({
        url: `/attendance/${employeeId}`,
        method: 'GET',
      }),
    }),
    updateAttendanceStatus: builder.mutation({
      query: ({companyId }) => ({
        url: `/attendance/${companyId}/status`,
        method: 'PATCH',
      }),
    }),

  }),
});

export const { useEmployeesAttendanceQuery, useCheckInAttendanceMutation, useCheckOutAttendanceMutation,useGetEmployeeAttendanceQuery, useUpdateAttendanceStatusMutation } = attendanceApi;
