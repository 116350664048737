import React, { useEffect, useState } from "react";
import { User, AttendanceRecord } from "../../types";
import { Divider, Table, Row, Col, Spin, Typography, message, DatePicker, Select, Button } from "antd";
import { format, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import Search from "antd/es/input/Search";
import { useEmployeesAttendanceQuery, useGetEmployeeQuery, useUpdateAttendanceStatusMutation } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ColumnType } from "antd/es/table";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export const AttendanceHistory: React.FC = () => {
  const [attendanceData, setAttendanceData] = useState<AttendanceRecord[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("daily");
  const [selectedUsername, setSelectedUsername] = useState<string>("");
  const { user: companyData } = useSelector((state: RootState) => state.auth);

  const { data: attendanceDataResponse, refetch: fetchAttendanceData } = useEmployeesAttendanceQuery(companyData?.id);
  const { data: employeeData, isLoading: employeesLoading, isFetching: employeesFetching, error: employeeError } = useGetEmployeeQuery(companyData?.id);

  const [filteredAttendance, setFilteredAttendance] = useState<AttendanceRecord[]>([]);
  const [updateAttendanceStatus] = useUpdateAttendanceStatusMutation()

  useEffect(() => {
    fetchAttendanceData();

    if (attendanceDataResponse) {
      const fetchedAttendanceData = attendanceDataResponse.data;
      if (Array.isArray(fetchedAttendanceData)) {
        setAttendanceData(fetchedAttendanceData);
      }
    }
  }, [attendanceDataResponse, companyData]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (employeeData && Array.isArray(employeeData.data)) {
          const filteredUsers = employeeData.data.filter((user: User) => user.role === "employee" || user.role === "manager");
          setUsers(filteredUsers);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [employeeData]);

  useEffect(() => {
    const applyFilters = () => {
      let filteredData = [...attendanceData];
      if (startDate && endDate) {
        filteredData = filteredData.filter((record) => {
          const recordDate = new Date(record.date);
          const start = new Date(startDate);
          const end = new Date(endDate);
          return recordDate >= start && recordDate <= end;
        });
      } else {
        switch (filterType) {
          case "daily":
            filteredData = filteredData.filter((record) => {
              const recordDate = new Date(record.date);
              const today = new Date();
              return recordDate >= startOfDay(today) && recordDate <= endOfDay(today);
            });
            break;
          case "weekly":
            filteredData = filteredData.filter((record) => {
              const recordDate = new Date(record.date);
              const today = new Date();
              return recordDate >= startOfWeek(today) && recordDate <= endOfWeek(today);
            });
            break;
          case "monthly":
            filteredData = filteredData.filter((record) => {
              const recordDate = new Date(record.date);
              const today = new Date();
              return recordDate >= startOfMonth(today) && recordDate <= endOfMonth(today);
            });
            break;
          case "yearly":
            filteredData = filteredData.filter((record) => {
              const recordDate = new Date(record.date);
              const today = new Date();
              return recordDate >= startOfYear(today) && recordDate <= endOfYear(today);
            });
            break;
          default:
            break;
        }
      }
      if (searchName) {
        const filteredUsers = users.filter((user) =>
          user.name?.toLowerCase().includes(searchName.toLowerCase())
        );
        const filteredUserIds = filteredUsers.map((user) => user._id);
        filteredData = filteredData.filter((record) => filteredUserIds.includes(record.employee._id));
      }
      if (selectedUsername) {
        filteredData = filteredData.filter((record) => record.employee.name === selectedUsername);
      }

      setFilteredAttendance(filteredData);
    };

    applyFilters();
  }, [attendanceData, users, searchName, startDate, endDate, filterType, selectedUsername]);

  const convertToHHMMSS = (decimalHours: number): string => {
    if (isNaN(decimalHours)) {
      return "N/A";
    }
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "PRESENT":
        return "green";
      case "ABSENT":
        return "red";
      case "HALF DAY":
        return "blue";
      case "LEAVE":
        return "orange";
      default:
        return "gray";
    }
  };

  const columns: ColumnType<AttendanceRecord>[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => format(new Date(date), "PPP"),
    },
    {
      title: "Name",
      dataIndex: ["employee", "name"],
      key: "name",
    },
    {
      title: "CheckedIn",
      dataIndex: "time_in",
      key: "time_in",
      render: (time_in: string) => format(new Date(time_in), "hh:mm a"),
    },
    {
      title: "CheckedOut",
      dataIndex: "time_out",
      key: "time_out",
      render: (time_out: string) => {
        if (!time_out || isNaN(Date.parse(time_out))) {
          return "N/A";
        }
        return format(new Date(time_out), "hh:mm a");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span
          style={{
            backgroundColor: getStatusColor(status),
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline-block",
          }}
        >
          {status}
        </span>
      ),
    },
    {
      title: "Working Hours",
      dataIndex: "working_hours",
      key: "working_hours",
      render: (working_hours: number) => convertToHHMMSS(working_hours),
    },
  ];
  const handleUpdateStatus = async () => {
    try {
      const response: any = await updateAttendanceStatus({ companyId: companyData?.id }).unwrap();
      if (response) {
        const { message: apiMessage } = response;

        if (apiMessage === 'No pending attendance records found') {
          message.info('No Pending Attendance found ');
        } else if (apiMessage === 'Invalid company ID') {
          message.error('Invalid company ID. Please check and try again.');
        } else if (apiMessage === 'No employees found for this company') {
          message.warning('No employees found for this company.');
        } else if (apiMessage.includes('attendance records updated')) {
          message.success(apiMessage);
          fetchAttendanceData();
        } else {
          message.error('Unexpected response: ' + apiMessage);
        }
      } 
      else {
        message.error('Error: No data received from the server.');
      }
    } catch (error: any) {
      console.error(error);

      if (error.response) {
        message.error(error.response?.data?.message || 'An unexpected error occurred.');
      } else if (error.request) {
        message.error('Network error. Please check your connection and try again.');
      } else {
        message.error('An unexpected error occurred. Please try again later.');
      }
    }
  };
  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <div
        className="flex flex-wrap justify-between bg-slate-200 p-2 rounded-md"
        style={{ position: "sticky", top: 0, zIndex: 1 }}
      >
        <div className="border-l-4 border-secondary-color h-9 flex items-center mb-2">
          <Title level={5} className="ml-2">
            Attendance
          </Title>
        </div>
        <Row justify="space-between" align="middle">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "10px" }}>Filter by:</span>
            <Select
              defaultValue="daily"
              style={{ width: 120, marginRight: "10px" }}
              onChange={(value) => setFilterType(value)}
            >
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>
            <RangePicker
              onChange={(dates, dateStrings) => {
                if (dates && dates.length === 2) {
                  const [startDate, endDate] = dateStrings;
                  setStartDate(startDate);
                  setEndDate(endDate);
                } else {
                  setStartDate("");
                  setEndDate("");
                }
              }}
              style={{ marginRight: "10px" }}
            />
            <Select
              showSearch
              placeholder="Select Employee Name"
              style={{ width: 200, marginRight: "10px" }}
              onChange={(value) => setSearchName(value)} 
              allowClear
              filterOption={(input, option: any) => {
                const optionText = option?.children;
                if (typeof optionText === "string") {
                  return optionText.toLowerCase().includes(input.toLowerCase());
                }
                return false;
              }}
            >
              {users.map((user) => (
                <Select.Option key={user._id} value={user.name}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
            {/* <Search
              placeholder="Search by Name"
              allowClear
              onSearch={(value) => setSearchName(value)}
              style={{ width: 200, margin: "0" }}
            /> */}
          </div>
          <Col>
            <Button onClick={handleUpdateStatus}>
              Update Leaves Credits
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <Table
        dataSource={filteredAttendance}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{ pageSize: 10 }}
        style={{ marginBottom: "25px" }}
      />

    </div>
  );
};
