import React, { useEffect, useState } from "react";
import {
  Divider,
  Table,
  Row,
  Col,
  Spin,
  Typography,
  message,
  Modal,
  Button,
  Input,
  Form,
  Avatar,
  Empty,
  Tooltip,
  Select,
} from "antd";
import { User, UserData } from "../../types";
import { FaUserAltSlash } from "react-icons/fa";
import moment from "moment";
import Search from "antd/es/input/Search";
import { AddUserModal } from "./AddUserModal";
import { EditOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import { useGetEmployeeQuery, useUpdateEmployeeStatusMutation } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from '../../../store/store';

const { Title } = Typography;

export const Employees: React.FC = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<User[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<UserData | null>(null);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { user: companyData } = useSelector((state: RootState) => state.auth);
  const [statusFilter, setStatusFilter] = useState<string>("active");
  const { data, isLoading, isFetching, refetch: fetchUsersData } = useGetEmployeeQuery(companyData?.id);
  const [updateEmployeeStatus] = useUpdateEmployeeStatusMutation();

  useEffect(() => {
    if (data) {
      setUsers(data.data);
    }
  }, [data]);

  const openModal = (url: string) => {
    const fileType = url.split(".").pop()?.toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileType || "")) {
      setModalContent(<img src={url} alt="Document" style={{ width: "100%", height: "auto" }} />);
    } else if (["pdf"].includes(fileType || "")) {
      setModalContent(<iframe src={url} style={{ width: "100%", height: "80vh" }} title="PDF Document"></iframe>);
    } else if (["csv", "xlsx", "xls"].includes(fileType || "")) {
      setModalContent(<iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${url}`} style={{ width: "100%", height: "80vh" }} title="Spreadsheet Document"></iframe>);
    } else {
      setModalContent(<p>Unsupported document type</p>);
    }
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const handleRowClick = (record: User, columnIndex: number) => {
    if (columns[columnIndex].key !== "action") {
      setSelectedUser(record);
      setIsReadOnlyModalOpen(true);
    } else {
      setIsReadOnlyModalOpen(false);
    }
  };

  const refreshData = () => {
    fetchUsersData();
  };

  const handleStatusUpdate = async (userId: string) => {
    try {
      const userToUpdate = users.find((user) => user._id === userId);
      if (!userToUpdate) {
        throw new Error(`User with ID ${userId} not found`);
      }

      const newStatus = userToUpdate.status === "active" ? "inactive" : "active";

      Modal.confirm({
        title: "Confirm Status Change",
        content: `Are you sure you want to set user status to "${newStatus}"?`,
        centered: true,
        onOk: async () => {
          await updateEmployeeStatus({ _id: userId, status: newStatus }).unwrap();
          setUsers((prevUsers) =>
            prevUsers.map((user) => (user._id === userId ? { ...user, status: newStatus } : user))
          );
          message.success(`User status updated to ${newStatus} successfully`);
        },
        onCancel: () => {
          message.info("Status update canceled");
        },
      });
    } catch (error) {
      message.error("Failed to update user status");
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      default:
        return "gray";
    }
  };

  const showModal = (userData?: UserData, userId?: string) => {
    if (userData) {
      setUser(userData);
      setIsEditing(true);
      form.setFieldsValue({ ...userData, password: "", confirmPassword: "" });
    } else {
      setUser(null);
      setIsEditing(false);
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value.trim().toLowerCase());
  };
  <Select
    defaultValue="all"
    onChange={(value) => setStatusFilter(value)}
    style={{ width: 200 }}
  >
    <Select.Option value="all">All Employees</Select.Option>
    <Select.Option value="active">Active Employees</Select.Option>
    <Select.Option value="inactive">Inactive Employees</Select.Option>
  </Select>

  // const filteredUsers = users.filter((user) => {
  //   const { _id, name, cnic, designation, status, email, role, fatherName } = user;
  //   return (
  //     (_id?.toString()?.toLowerCase().includes(searchName.toLowerCase()) || false) ||
  //     (name?.toLowerCase().includes(searchName) || false) ||
  //     (email?.toLowerCase().includes(searchName) || false) ||
  //     (role?.toLowerCase().includes(searchName) || false) ||
  //     (designation?.toLowerCase().includes(searchName) || false) ||
  //     (cnic?.toLowerCase().includes(searchName) || false) ||
  //     (fatherName?.toLowerCase().includes(searchName) || false) ||
  //     (status?.toLowerCase().includes(searchName) || false)
  //   );
  // });
  const filteredUsers = users.filter((user) => {
    const { _id, name, cnic, designation, status, email, role, fatherName } = user;

    // Filter by search query
    const matchesSearch =
      (_id?.toString()?.toLowerCase().includes(searchName.toLowerCase()) || false) ||
      (name?.toLowerCase().includes(searchName) || false) ||
      (email?.toLowerCase().includes(searchName) || false) ||
      (role?.toLowerCase().includes(searchName) || false) ||
      (designation?.toLowerCase().includes(searchName) || false) ||
      (cnic?.toLowerCase().includes(searchName) || false) ||
      (fatherName?.toLowerCase().includes(searchName) || false) ||
      (status?.toLowerCase().includes(searchName) || false);

    // Filter by status
    const matchesStatus =
      statusFilter === "all" ||
      statusFilter === status.toLowerCase();

    return matchesSearch && matchesStatus;
  });

  const columns = [
    {
      title: "Profile Image",
      dataIndex: ["profileImage"],
      key: "profileImage",
      render: (profileImage: string) => <Avatar src={profileImage || "/assets/menIcon.jpeg"} />,
    },

    // {
    //   title: "Employee ID",
    //   dataIndex: ["_id"],
    //   key: "_id",
    // },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "fullName",
    },
    {
      title: "Father Name",
      dataIndex: ["fatherName"],
      key: "fatherName",
    },
    {
      title: "Email",
      dataIndex: ["email"],
      key: "email",
    },
    {
      title: "Role",
      dataIndex: ["role"],
      key: "role",
    },
    {
      title: "Designation",
      dataIndex: ["designation"],
      key: "designation",
    },
    {
      title: "Leave Balance",
      dataIndex: ["leaveBalance"],
      key: "leaveBalance",
    },
    // {
    //   title: "CNIC",
    //   dataIndex: ["cnic"],
    //   key: "cnic",
    // },
    {
      title: "Joining Date",
      dataIndex: ["createdAt"],
      key: "joiningDate",
      render: (text: string) => moment(text).format("MMMM Do YYYY"),
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (status: string) => (
        <span
          style={{
            backgroundColor: getStatusColor(status),
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline-block",
          }}
        >
          {status}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text: any, record: User) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>
            <Tooltip title="Edit">
              <EditOutlined style={{ fontSize: 20, cursor: "pointer" }} onClick={() => showModal(record, record._id)} />
            </Tooltip>
          </span>
          <span>
            <Tooltip title="Status">
              <FaUserAltSlash style={{ fontSize: 20, color: "red", cursor: "pointer" }} onClick={() => handleStatusUpdate(record._id)} />
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  if (isLoading || isFetching) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex justify-between bg-slate-200 p-1 rounded-md w-full">
        {isModalOpen && (
          <AddUserModal
            openModal={isModalOpen}
            closeModal={closeModal}
            userData={user}
            onModalClose={refreshData}
            fetchUsersData={fetchUsersData}
          />
        )}

        <div className="border-l-4 border-secondary-color h-9 flex items-center">
          <Title level={5} className="ml-2">
            Employees
          </Title>
        </div>
        <Row justify="space-between" align="middle">
          <Col>
            <Search
              placeholder="Search"
              allowClear
              onChange={handleSearch}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            {
              <AddUserModal
                onModalClose={refreshData}
                fetchUsersData={fetchUsersData}
              />
            }{" "}
          </Col>
          <Col>
        <Select
          defaultValue="active"
          onChange={(value) => setStatusFilter(value)}
          style={{ width: 200 }}
        >
          <Select.Option value="all">All Employees</Select.Option>
          <Select.Option value="active">Active Employees</Select.Option>
          <Select.Option value="inactive">Inactive Employees</Select.Option>
        </Select>
      </Col>
        </Row>
      </div>
      <Divider />
      <Table
        dataSource={filteredUsers}
        columns={columns.map((col, index) => ({
          ...col,
          onCell: (record: User) => ({
            onClick: () => handleRowClick(record, index),
          }),
        }))}
      />
      <Modal
        key={selectedUser ? selectedUser._id : "default"}
        title="Employee Details"
        visible={isReadOnlyModalOpen}
        className="!w-[70%]"
        onCancel={() => setIsReadOnlyModalOpen(false)}
        footer={null}
      >
        <Divider className="!mt-3" />

        {selectedUser && (
          <div>
            <Row gutter={[16, 0]}>
              <Col xs={8} lg={6} style={{ marginTop: 20 }}>
                <Form.Item style={{ textAlign: "center" }}>
                  <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                    <Title level={5} className="ml-2">
                      Profile Image
                    </Title>
                  </div>

                  <div style={{ textAlign: "center", marginTop: "8px" }}>
                    <Avatar
                      alt="User Avatar"
                      src={selectedUser.profileImage}
                      style={{ width: 150, height: 150 }}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col xs={8} sm={2} md={16} lg={18}>
                <div
                  className="modalBody"
                  style={{
                    maxHeight: "60vh",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                  }}
                >
                  <div className="form-container">
                    <Form
                      className="mt-4 w-full"
                      name="basic"
                      labelCol={{ span: 8 }}
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <div className="mt-4 w-full">
                        <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                          <Title level={5} className="ml-2">
                            User Details
                          </Title>
                        </div>
                        <Row gutter={[16, 0]}>
                          {" "}
                          <Col xs={24} sm={12}>
                            <Form.Item label="Full Name" name="name">
                              <Input
                                defaultValue={selectedUser.name}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Father Name" name="fatherName">
                              <Input
                                defaultValue={
                                  selectedUser.fatherName
                                }
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Email",
                                },
                              ]}
                            >
                              <Input
                                defaultValue={selectedUser.email}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Address" name="address">
                              <Input
                                defaultValue={selectedUser.address}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              label="Phone Number"
                              labelCol={{ span: 10 }}
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Phone Number",
                                },
                              ]}
                            >
                              <Input
                                defaultValue={selectedUser.phone}
                                readOnly
                              />
                              {/* <PhoneInput
                                value={selectedUser.userDetail.phone}
                                disabled
                                inputStyle={{ width: "100%", height: "32px" }}
                                containerStyle={{
                                  width: "100%",
                                  height: "32px",
                                }} */}
                              {/* /> */}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="CNIC" name="cnic">
                              <Input
                                defaultValue={selectedUser.cnic}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              label="Select Gender"
                              labelCol={{ span: 10 }}
                              name="gender"
                            >
                              <Input
                                defaultValue={selectedUser.gender}
                                readOnly
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12}>
                            <Form.Item label="DOB" name="dob">
                              <Input
                                defaultValue={moment(
                                  selectedUser.dob
                                ).format("MMMM Do YYYY")}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1 mt-2">
                          <Title level={5} className="ml-2">
                            Job Details
                          </Title>
                        </div>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              label="Company Name"
                              labelCol={{ span: 10 }}
                              name="company"
                            >
                              <Input
                                defaultValue={
                                  selectedUser.company
                                }
                                readOnly
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12}>
                            <Form.Item label="Job Type" name="jobType">
                              <Input
                                defaultValue={selectedUser.jobType}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Department" name="department">
                              <Input
                                defaultValue={selectedUser.department}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              label="Select Designation"
                              labelCol={{ span: 12 }}
                              name="designation"
                            >
                              <Input
                                defaultValue={
                                  selectedUser.designation
                                }
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Manager" name="manager">
                              <Input
                                defaultValue={selectedUser.manager}
                                readOnly
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12}>
                            <Form.Item label="Select role" name="role">
                              <Input
                                defaultValue={selectedUser.role}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Salary" name="salary">
                              <Input
                                defaultValue={selectedUser.salary}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Joining Date" name="joiningDate">
                              <Input
                                defaultValue={moment(
                                  selectedUser.joiningDate
                                ).format("MMMM Do YYYY")}
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={12}></Col>
                        </Row>
                        <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                          <Title level={5} className="ml-2">
                            SignIn Details
                          </Title>
                        </div>
                        <Row gutter={[16, 0]}>
                          {" "}
                          <Col xs={24} sm={12}>
                            <Form.Item label="User Name" name="name">
                              <Input
                                defaultValue={
                                  selectedUser.name
                                }
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Personal Email" name="personalEmail">
                              <Input
                                type="email"
                                defaultValue={
                                  selectedUser.personalEmail
                                }
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className="">
                        <div className="border-l-4 border-secondary-color h-7 items-center mb-4">
                          <Title level={5} className="ml-2">
                            Employee Documents
                          </Title>
                        </div>
                        {selectedUser?.document &&
                          selectedUser.document?.length > 0 ? (
                          <>
                            <div className="flex flex-wrap gap-2">
                              {selectedUser.document
                                .filter((url: any) => {
                                  const fileType = url
                                    .split(".")
                                    .pop()
                                    ?.toLowerCase();
                                  return ["jpg", "jpeg", "png"].includes(
                                    fileType || ""
                                  );
                                })
                                .map((url: string, index: number) => (
                                  <div
                                    key={index}
                                    className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                                  >
                                    <Button
                                      type="link"
                                      onClick={() => openModal(url)}
                                      className="w-full"
                                    >
                                      <img
                                        src={url}
                                        alt={`Document ${index}`}
                                        className="object-cover w-full h-auto"
                                        style={{ maxHeight: "150px" }}
                                      />
                                    </Button>
                                  </div>
                                ))}
                              {selectedUser.document
                                .filter((url: any) => {
                                  const fileType = url
                                    .split(".")
                                    .pop()
                                    ?.toLowerCase();
                                  return ["pdf", "csv", "xlsx", "xls"].includes(
                                    fileType
                                  );
                                })
                                .map((url: string, index: number) => (
                                  <div
                                    key={index}
                                    className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                                  >
                                    <Button
                                      type="link"
                                      onClick={() => openModal(url)}
                                      className="w-full"
                                    >
                                      View Document
                                    </Button>
                                  </div>
                                ))}
                            </div>
                          </>
                        ) : (
                          <Empty description="No documents available" />
                        )}
                      </div>
                      <Modal
                        visible={isModalVisible}
                        title="Employee Document"
                        onCancel={handleModalClose}
                        footer={null}
                        width="80%"
                        bodyStyle={{ padding: 0 }}
                      >
                        <Divider className="!mt-3" />

                        {modalContent}
                      </Modal>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};
