import React from "react";
import { Route, Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const token = localStorage.getItem("AUTH_SESSION");
  return token ? element : <Navigate to="/" />;
};

export default PrivateRoute;
