import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const employeeApi = createApi({
    reducerPath: 'employeeApi',
    baseQuery,
    endpoints: (builder) => ({
        getEmployeeProfile: builder.query({
            query: () => ({
                url: `/employees/profile`,
                method: 'GET',
            }),
        }),
        updateEmployeeProfile: builder.mutation({
            query: ({ employeeId, credentials }) => ({
              url: `/employees/${employeeId}`,
              method: 'PATCH',
              data: credentials,
            }),
          }),
        changePassword: builder.mutation({
            query: ({ employeeId, credentials }) => ({
              url: `/employees/${employeeId}/change-password`,
              method: 'POST',
              data: credentials,
            }),
          }),
    }),
});
export const { useGetEmployeeProfileQuery,  useUpdateEmployeeProfileMutation,useChangePasswordMutation } = employeeApi;
