import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const salaryApi = createApi({
    reducerPath: 'salaryApi',
    baseQuery,
    endpoints: (builder) => ({
        generateEmployeesSalary: builder.mutation({
            query: ({ companyId, month, year}) => ({
                url: `/salary/${companyId}`,
                method: 'POST',
                data: {month,year},
            }),
        }),
        getEmployeesSalary: builder.query({
            query: ({ companyId, month, year}) => ({
                url: `/salary/${companyId}`,
                method: 'GET',
                params: {month,year},
            }),
        }),

    }),
});

export const { useGenerateEmployeesSalaryMutation, useGetEmployeesSalaryQuery } = salaryApi;
