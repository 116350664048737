import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { getToken, removeToken } from '../utils/tokenManager'; // Utility to manage tokens
import { ENVIROMENT } from '../constants';

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: ENVIROMENT.API_URL,
  timeout: 10000,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken(); // Get token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error('Unauthorized! Handling 401...');
      removeToken();

      // Add logic for refreshing tokens or redirecting to login
    }
    return Promise.reject(error);
  }
);

// Custom base query
export const baseQuery: BaseQueryFn<
  { url: string; method: AxiosRequestConfig['method']; data?: unknown; params?: unknown },
  unknown,
  unknown
> = async ({ url, method, data, params }) => {
  try {
    const result = await axiosInstance({ url, method, data, params });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
