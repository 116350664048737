import React, { useEffect, useState } from "react";
import { Modal, Divider, DatePicker, Button, Form, Row, Col, Input, message, Upload } from "antd";
import dayjs from "dayjs";

interface HolidayModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  loading: boolean;
  onFinish: (values: any, fileList: any[]) => void;
  onFinishFailed: (errorInfo: any) => void;
  form: any;
  holiday?: any | null;
}

export const HolidayModal: React.FC<HolidayModalProps> = ({
  isModalOpen,
  handleCancel,
  loading,
  onFinish,
  onFinishFailed,
  form,
  holiday,
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    if (holiday) {
      if (holiday.startDate && holiday.endDate) {
        form.setFieldsValue({
          title: holiday.title,
          startDate: dayjs(holiday.startDate), 
          endDate: dayjs(holiday.endDate),    
        });
      } else {
        console.error("Invalid date provided for start or end date");
      }
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [holiday, form]);

  const handleSubmit = (values: any) => {
    const startDate = dayjs(values.startDate);
    const endDate = dayjs(values.endDate);
    if (!startDate.isValid() || !endDate.isValid()) {
      message.error("Invalid date values.");
      return;
    }

    setUploading(true);
    onFinish(values, fileList);
    setUploading(false);
    handleCancel();
  };

  const handleReset = () => {
    setFileList([]);
    setUploading(false);
    form.resetFields();
  };

  return (
    <Modal
      title={holiday ? "Edit Holiday" : "Add Holiday"}
      visible={isModalOpen}
      onCancel={handleCancel}
      className="!w-[40%]"
      confirmLoading={loading}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleReset();
            handleCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={loading || uploading}
        >
          {holiday ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Divider className="!mt-3" />
      <Form
        form={form}
        className="mt-4 w-full"
        name="holiday"
        labelCol={{ span: 8 }}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Form.Item
            label="Holiday Title"
            name="title"
            rules={[{ required: true, message: "Please input the holiday title!" }]}
            style={{ width: "100%", marginRight: "8px", marginLeft: "8px" }}
          >
            <Input placeholder="Holiday title" />
          </Form.Item>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Please select the start date" }]}
            >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                  format="YYYY-MM-DD"
                />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "Please select the end date" }]}
            >
            <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                  format="YYYY-MM-DD"
                />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
