// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { getNotification, readNotification } from '../../../services/notification/notification';
// // import { Notification } from '../../types';

// // const NotificationIcon: React.FC = () => {
// //   const [notifications, setNotifications] = useState<Notification[]>([]);
// //   const [unreadCount, setUnreadCount] = useState<number>(0);

// //   useEffect(() => {
// //     const fetchNotifications = async () => {
// //       try {
// //         const data = await getNotification();
// //         const notificationsData = data.notifications;

// //         setNotifications(notificationsData);
// //         const unread = notificationsData.filter(notification => !notification.isRead).length;
// //         setUnreadCount(unread);
// //       } catch (error) {
// //         console.error('Error fetching notifications:', error);
// //       }
// //     };

// //     fetchNotifications();
// //   }, []);
// //   const handleNotificationClick = async (notificationId: string) => {
// //     try {
// //       const data = await readNotification(notificationId);

// //       setNotifications(prevNotifications =>
// //         prevNotifications.map((notification: Notification) =>
// //           notification._id === notificationId
// //             ? { ...notification, isRead: true }
// //             : notification
// //         )
// //       );
// //       setUnreadCount(prevUnreadCount => prevUnreadCount - 1);  // Update unread count
// //     } catch (error) {
// //       console.error('Error marking notification as read:', error);
// //     }
// //   };

// //   return (
// //     <div className="notification-icon">
// //       <button>
// //         <span className="bell-icon">🔔</span>
// //         {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
// //       </button>

// //       {/* Notifications Dropdown */}
// //       {notifications.length > 0 && (
// //         <div className="notifications-dropdown">
// //           <ul>
// //             {notifications.map((notification: Notification) => (
// //               <li
// //                 key={notification._id}
// //                 className={notification.isRead ? 'read' : 'unread'}
// //                 onClick={() => handleNotificationClick(notification._id)}
// //               >
// //                 <span>{notification.message}</span>
// //                 <span>{new Date(notification.createdAt).toLocaleString()}</span>
// //               </li>
// //             ))}
// //           </ul>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default NotificationIcon;


// // import React, { useState, useEffect } from 'react';
// // import { getNotification, readNotification } from '../../../services/notification/notification';
// // import { Notification } from '../../types';


// // const NotificationIcon: React.FC = () => {
// //   const [notifications, setNotifications] = useState<Notification[]>([]);
// //   const [unreadCount, setUnreadCount] = useState<number>(0);
// //   const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false); // Track visibility of the dropdown

// //   useEffect(() => {
// //     const fetchNotifications = async () => {
// //       try {
// //         const data = await getNotification();
// //         const notificationsData = data.notifications;

// //         setNotifications(notificationsData);

// //         // Calculate unread count
// //         const unread = notificationsData.filter(notification => !notification.isRead).length;
// //         setUnreadCount(unread);
// //       } catch (error) {
// //         console.error('Error fetching notifications:', error);
// //       }
// //     };

// //     fetchNotifications();
// //   }, []); // Fetch notifications on mount

// //   const handleNotificationClick = async (notificationId: string) => {
// //     try {
// //       // Call API to mark the notification as read
// //       await readNotification(notificationId);

// //       // Update notifications state to mark the notification as read
// //       setNotifications(prevNotifications =>
// //         prevNotifications.map((notification: Notification) =>
// //           notification._id === notificationId
// //             ? { ...notification, isRead: true }
// //             : notification
// //         )
// //       );

// //       // Decrease unread count
// //       setUnreadCount(prevUnreadCount => prevUnreadCount - 1);
// //     } catch (error) {
// //       console.error('Error marking notification as read:', error);
// //     }
// //   };

// //   const toggleDropdown = () => {
// //     setIsDropdownVisible(!isDropdownVisible); // Toggle the dropdown visibility
// //   };

// //   return (
// //     <div className="notification-icon">
// //       <button onClick={toggleDropdown}>
// //         <span className="bell-icon">🔔</span>
// //         {unreadCount > 0 && <span className="badge">{unreadCount}</span>} {/* Display unread count */}
// //       </button>

// //       {/* Notifications Dropdown */}
// //       {isDropdownVisible && notifications.length > 0 && (
// //         <div className="notifications-dropdown">
// //           <ul>
// //             {notifications.map((notification: Notification) => (
// //               <li
// //                 key={notification._id}
// //                 className={notification.isRead ? 'read' : 'unread'}
// //                 onClick={() => handleNotificationClick(notification._id)} // Mark notification as read when clicked
// //               >
// //                 <span>{notification.message}</span>
// //                 <span>{new Date(notification.createdAt).toLocaleString()}</span>
// //               </li>
// //             ))}
// //           </ul>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default NotificationIcon;

// import React, { useState, useEffect } from 'react';
// import { getNotification, readNotification } from '../../../services/notification/notification';
// import { Notification } from '../../types';
// import { BellOutlined } from '@ant-design/icons'; 
// import { Badge, Popover } from 'antd'; 

// const NotificationIcon: React.FC = () => {
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [unreadCount, setUnreadCount] = useState<number>(0);
//   const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const data = await getNotification();
//         const notificationsData = data.notifications;

//         setNotifications(notificationsData);
//         const unread = notificationsData.filter(notification => !notification.isRead).length;
//         setUnreadCount(unread);
//       } catch (error) {
//         console.error('Error fetching notifications:', error);
//       }
//     };

//     fetchNotifications();
//   }, []); 

//   const handleNotificationClick = async (notificationId: string) => {
//     try {
//       await readNotification(notificationId);

//       setNotifications(prevNotifications =>
//         prevNotifications.map((notification: Notification) =>
//           notification._id === notificationId
//             ? { ...notification, isRead: true }
//             : notification
//         )
//       );
//       setUnreadCount(prevUnreadCount => prevUnreadCount - 1);
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };
//   const renderNotificationsDropdown = () => {
//     return (
//       <div className="notifications-dropdown">
//         <ul>
//           {notifications.map((notification: Notification) => (
//             <li
//               key={notification._id}
//               className={notification.isRead ? 'read' : 'unread'}
//               onClick={() => handleNotificationClick(notification._id)}
//             >
//               <span>{notification.message}</span>
//               <span>{new Date(notification.createdAt).toLocaleString()}</span>
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   };

//   return (
//     <div className="notification-icon">
//       <Popover
//         content={renderNotificationsDropdown()} 
//         title="Notification"
//         trigger="click"
//         visible={isDropdownVisible}
//         onVisibleChange={setIsDropdownVisible}
//       >
//         <Badge count={unreadCount} overflowCount={99}>
//           <BellOutlined style={{ fontSize: '24px', color: unreadCount > 0 ? 'red' : 'gray' }} />
//         </Badge>
//       </Popover>
//     </div>
//   );
// };

// export default NotificationIcon;

import React, { useState, useEffect } from 'react';
import { getNotification, readNotification } from '../../../services/notification/notification';
import { Notification, UserData } from '../../types';
import { BellOutlined } from '@ant-design/icons';
import { Badge, Popover, Button, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchUserData } from '../../../services/userApis/userApis';

const NotificationIcon: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const [vibrate, setVibrate] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData | null>(null);
    const navigate = useNavigate();
    const categorizeNotificationsByDate = (notifications: Notification[]) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
      
        const todayNotifications:any = [];
        const yesterdayNotifications:any = [];
        const olderNotifications:any = [];
      
        notifications.forEach(notification => {
          const notificationDate = new Date(notification.createdAt);
          if (notificationDate.toDateString() === today.toDateString()) {
            todayNotifications.push(notification);
          } else if (notificationDate.toDateString() === yesterday.toDateString()) {
            yesterdayNotifications.push(notification);
          } else {
            olderNotifications.push(notification);
          }
        });
      
        return { todayNotifications, yesterdayNotifications, olderNotifications };
      };
      useEffect(() => {
        const fetchData = async () => {
          const userDetail = await fetchUserData();
          setUserData(userDetail);
        };
        fetchData();
      }, []);
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const data = await getNotification();
                const notificationsData = data.notifications;
                const filteredNotifications = notificationsData.filter(
                    notification => notification.manager === userData?.manager
                );
                setNotifications(filteredNotifications);
                const unread = filteredNotifications.filter(notification => !notification.isRead).length;
                setUnreadCount(unread);
                if (unread > 0) {
                    setVibrate(true);
                  } else {
                    setVibrate(false);
                  }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        if (userData) {
            fetchNotifications();
        }
        const intervalId = setInterval(() => {
            if (userData) {
                fetchNotifications();
            }
        }, 600000);
        return () => clearInterval(intervalId);
    }, [userData]); 
    
    const formatNotificationMessage = (message: string) => {
        const usernameMatch = message.match(/^([a-zA-Z0-9_]+)(?=\s+has requested leave)/);
        const dateMatch = message.match(/from\s+(\d{4}-\d{2}-\d{2})\s+to\s+(\d{4}-\d{2}-\d{2})/);
      
        if (usernameMatch && dateMatch) {
          const username = usernameMatch[1];
          const fromDate = dateMatch[1];     
          const toDate = dateMatch[2]; 
          const formatDate = (dateStr: string) => {
            const date = new Date(dateStr);
            const options: Intl.DateTimeFormatOptions = {
              year: 'numeric',
              month: 'long',  
              day: 'numeric',
            };
            return date.toLocaleDateString('en-US', options);
          };
          const formattedFromDate = formatDate(fromDate);
          const formattedToDate = formatDate(toDate);
          return message
            .replace(username, `<b>${username}</b>`) 
            .replace(fromDate, `<b>${formattedFromDate}</b>`)  
            .replace(toDate, `<b>${formattedToDate}</b>`);   
        }
      
        return message; 
      };
      const triggerVibration = () => {
        if (vibrate) {
          navigator.vibrate([300, 200, 300]); 
        }
      };
      useEffect(() => {
        triggerVibration();
      }, [unreadCount, vibrate]);
      const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
      };
      const handleNotificationClick = async (notificationId: string) => {
        try {
            const notification = notifications.find((notif) => notif._id === notificationId);
            if (notification && !notification.isRead) {
                await readNotification(notificationId);
                setNotifications(prevNotifications =>
                    prevNotifications.map((notif) =>
                        notif._id === notificationId
                            ? { ...notif, isRead: true }
                            : notif
                    )
                );
                setUnreadCount(prevUnreadCount => prevUnreadCount - 1);

            }
            const leaveApplicationId = notification?.leaveApplicationId;
            if (leaveApplicationId) {
                navigate(`/dashboard/leave-applications`, { state: { leaveApplicationId } });
                setIsDropdownVisible(false);
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleMarkAllRead = async () => {
        try {
            await readNotification('all');
            setNotifications(prevNotifications =>
                prevNotifications.map(notification => ({ ...notification, isRead: true }))
            );

            setUnreadCount(0);
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };
    const renderNotificationsDropdown = () => {
        const { todayNotifications, yesterdayNotifications, olderNotifications } = categorizeNotificationsByDate(notifications);
    return (
      <div className="notifications-dropdown">
        <Button type="link" onClick={handleMarkAllRead} style={{ width: '100%' ,marginRight:"0" }}>
          • Mark all as Read
        </Button>
        <ul>
          {todayNotifications.length > 0 && (
            <>
              <li style={{textAlign:"left" , fontWeight:"300" ,marginLeft:"6px"}}>Today</li>
              {todayNotifications.map((notification: Notification) => (
                <React.Fragment key={notification._id}>
                  <li
                    className={notification.isRead ? 'read' : 'unread'}
                    onClick={() => handleNotificationClick(notification._id)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatNotificationMessage(notification.message),
                      }}
                    />
                    <span>{new Date(notification.createdAt).toLocaleString()}</span>
                  </li>
                  <Divider style={{ margin: "4px 0" }} />
                </React.Fragment>
              ))}
            </>
          )}

          {yesterdayNotifications.length > 0 && (
            <>
              <li style={{textAlign:"left" , fontWeight:"300" ,marginLeft:"6px"}}>Yesterday</li>
              {yesterdayNotifications.map((notification: Notification)  => (
                <React.Fragment key={notification._id}>
                  <li
                    className={notification.isRead ? 'read' : 'unread'}
                    onClick={() => handleNotificationClick(notification._id)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatNotificationMessage(notification.message),
                      }}
                    />
                    <span>{new Date(notification.createdAt).toLocaleString()}</span>
                  </li>
                  <Divider style={{ margin: "4px 0" }} />
                </React.Fragment>
              ))}
            </>
          )}

          {olderNotifications.length > 0 && (
            <>
              <li style={{textAlign:"left", fontWeight:"300" ,marginLeft:"6px"}}> Older</li>
              {olderNotifications.map((notification: Notification )=> (
                <React.Fragment key={notification._id}>
                  <li
                    className={notification.isRead ? 'read' : 'unread'}
                    onClick={() => handleNotificationClick(notification._id)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatNotificationMessage(notification.message),
                      }}
                    />
                    <span>{new Date(notification.createdAt).toLocaleString()}</span>
                  </li>
                  <Divider style={{ margin: "4px 0" }} />
                </React.Fragment>
              ))}
            </>
          )}
        </ul>
      </div>
    );
  };


    return (
        <div className="notification-icon">
            <Popover
                content={renderNotificationsDropdown()}
                title="Notifications"
                trigger="click"
                visible={isDropdownVisible}
                onVisibleChange={setIsDropdownVisible}
            >
                <Badge count={unreadCount} overflowCount={99}>
                    <BellOutlined style={{ fontSize: '24px', color: unreadCount > 0 ? 'red' : 'gray', animation: unreadCount > 0 ? 'vibrate 0.5s infinite' : 'none', }} />
                </Badge>
            </Popover>
        </div>
    );
};

export default NotificationIcon;
