import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const announcementAPi = createApi({
  reducerPath: 'announcementAPi',
  baseQuery,
  endpoints: (builder) => ({
    createAnnouncement: builder.mutation({
      query: ({ companyId, announcementData }) => ({
        url: `/announcements/${companyId}`,
        method: 'POST',
        data: announcementData
      }),
    }),
    editAnnouncement: builder.mutation({
      query: ({ companyId, announcementData }) => ({
        url: `/announcements/${companyId}`,
        method: 'PUT',
        data: announcementData
      }),
    }),
    deleteAnnouncement: builder.mutation({
      query: ({ companyId,id }) => ({
        url: `/announcements/${companyId}/${id}`,
        method: 'DELETE',
      }),
    }),
    getAnnouncements: builder.query({
      query: (companyId) => ({
        url: `/announcements/${companyId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useCreateAnnouncementMutation, useGetAnnouncementsQuery , useEditAnnouncementMutation, useDeleteAnnouncementMutation} = announcementAPi;
