import { Notification } from "../../components/types";
import attendanceAPI from "../axios";



export const getNotification = async () => {
    try {
      const response = await attendanceAPI.get<{ notifications: Notification[] }>(
        `${process.env.REACT_APP_API_URL}/notification`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching notifications:", error);
      throw error;
    }
  };
  
  export const readNotification= async (notificationId: string)=>{
    try{
        const response = await attendanceAPI.post(`${process.env.REACT_APP_API_URL}/readNotification`, { notificationId });
        return response.data
    } catch (error) {
        console.error("Error Update the Notification:", error);
        throw error;
      }
    };