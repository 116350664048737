import React, { useState, useEffect } from 'react';
import { Button, Form, Select, message, Spin, Table, Avatar } from 'antd';
import { useGenerateEmployeesSalaryMutation, useGetEmployeesSalaryQuery } from '../../api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

interface SalaryData {
    userName: string;
    presentDays: number;
    absentDays: number;
    totalSalary: number;
    Usersalary: number;
    month: number;
    year: number;
    totalWorkingHours: number;
    halfDays: number;
    leaveDays: number;
    basicSalary: number;
}

const SalaryReport = () => {
    const { user: companyData } = useSelector((state: RootState) => state.auth);
    const [salaryData, setSalaryData] = useState<SalaryData[]>([]);
    const [month, setMonth] = useState<number | undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(undefined);
    const [actionType, setActionType] = useState<'get' | 'generate'>('get');
    const [loading, setLoading] = useState(false);

    // Fetch salary data only when 'Get Salary' is selected
    const { data: fetchedSalaryData, isFetching, error } = useGetEmployeesSalaryQuery(
        { companyId: companyData?.id, month, year },
        { skip: actionType === 'generate' || !month || !year }
    );

    const [generateSalary, { isLoading: isGenerating }] = useGenerateEmployeesSalaryMutation();

    const currentYear = new Date().getFullYear();
    useEffect(() => {
        if (actionType === 'get' && fetchedSalaryData) {
            console.log('Fetched Salary Data:', fetchedSalaryData);  

            if (fetchedSalaryData?.data) {
                const formattedData = fetchedSalaryData.data.map((item: any) => ({
                    profileImage:item.employee?.profileImage,
                    userName: item.employee?.name,
                    presentDays: item.presentDays,
                    absentDays: item.absentDays,
                    leaveDays: item.leaveDays,
                    halfDays: item.halfDays,
                    basicSalary: item.totalSalary,
                    totalSalary: item.payableSalary,
                    dedcutionAmount:item.deductionAmount,
                    holidayDays:item.holidayDays,
                }));

                setSalaryData(formattedData); 
            }
        }
    }, [fetchedSalaryData, actionType]); 

    const handleGenerateSalary = async () => {
        if (!month || !year) {
            message.error('Please select both Month and Year');
            return;
        }

        setLoading(true);
        try {
            const response = await generateSalary({
                companyId: companyData?.id,
                month,
                year,
            }).unwrap();

            if (response) {
                message.success('Salary generated successfully!');
                console.log('Generated Salary Response:', response); 
                setSalaryData(response.data); 
            }
        } catch (err) {
            console.error(err);
            message.error('Failed to generate salary');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadPDF = (record: SalaryData) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Salary Report', 14, 20);
        const tableData = [
            [record.userName, record.presentDays, record.absentDays, record.totalSalary, record.Usersalary],
        ];
        const tableColumns = ['User Name', 'Present Days', 'Absent Days', 'Total Salary', 'User Salary'];

        doc.autoTable({
            head: [tableColumns],
            body: tableData,
            startY: 30,
        });
        doc.save(`${record.userName}_salary.pdf`);
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(salaryData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Salary Report');
        XLSX.writeFile(workbook, 'salary_report.xlsx');
    };

    const handleActionChange = (value: 'get' | 'generate') => {
        setActionType(value);
        setSalaryData([]);

        if (value === 'get' && fetchedSalaryData) {

            const formattedData = fetchedSalaryData.data.map((item: any) => ({
                profileImage:item.employee?.profileImage,
                    userName: item.employee?.name,
                    presentDays: item.presentDays,
                    absentDays: item.absentDays,
                    leaveDays: item.leaveDays,
                    halfDays: item.halfDays,
                    basicSalary: item.totalSalary,
                    totalSalary: item.payableSalary,
                    dedcutionAmount:item.deductionAmount,
                    holidayDays:item.holidayDays,
            }));

            setSalaryData(formattedData);
        }
    };
    const handleDownloadPDF2 = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text('Salary Report', 14, 20);
        const tableData = salaryData.map((item) => [
            item.userName,
            item.presentDays,
            item.absentDays,
            item.totalSalary,
            item.Usersalary,
        ]);
        const tableColumns = ['User Name', 'Present Days', 'Absent Days', 'Total Salary', 'User Salary'];

        doc.autoTable({
            head: [tableColumns],
            body: tableData,
            startY: 30,
        });
        doc.save('salary_report.pdf');
    };

    const columns = [
        {
            title: "Profile Image",
            dataIndex: ['profileImage'],
            key: "profileImage",
            render: (profileImage: string) => <Avatar src={profileImage || "/assets/menIcon.jpeg"} />,
        },
        { title: 'Employee', dataIndex: [ "userName"], key: 'userName' },
        { title: 'Present Days', dataIndex: 'presentDays', key: 'presentDays' },
        { title: 'Half Days', dataIndex: 'halfDays', key: 'halfDays' },
        { title: 'Leave Days', dataIndex: 'leaveDays', key: 'leaveDays' },
        { title: 'Absent Days', dataIndex: 'absentDays', key: 'absentDays' },
        { title: 'Holidays', dataIndex: 'holidayDays', key: 'holidayDays' },
        { title: 'Payable Salary', dataIndex: 'totalSalary', key: 'totalSalary' },
        { title: 'Deduction Amount', dataIndex: 'dedcutionAmount', key: 'dedcutionAmount' },
        { title: 'Basic Salary', dataIndex: 'basicSalary', key: 'basicSalary' },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: SalaryData) => (
                <Button type="link" onClick={() => handleDownloadPDF(record)}>
                    Download PDF
                </Button>
            ),
        },
    ];

    return (
        <div>
            <div className="flex justify-between items-center bg-slate-200 p-3 rounded-md w-full">
                <div className="flex items-center border-l-4 border-secondary-color pl-2 h-9 w-full">
                    <Form layout="inline" className="w-full flex items-center space-x-4">
                        <Form.Item name="action" label="Action" className="flex items-center">
                            <Select
                                value={actionType}
                                onChange={handleActionChange}
                                className="w-36"
                                placeholder="Select Action"
                            >
                                <Select.Option value="get">Get Salary</Select.Option>
                                <Select.Option value="generate">Generate Salary</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name="month" label="Select Month" className="flex items-center">
                            <Select
                                value={month}
                                onChange={(value) => setMonth(value)}
                                placeholder="Select Month"
                                className="w-36"
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <Select.Option key={i + 1} value={i + 1}>
                                        {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="year" label="Select Year" className="flex items-center">
                            <Select
                                value={year}
                                onChange={(value) => setYear(value)}
                                placeholder="Select Year"
                                className="w-32"
                            >
                                {Array.from({ length: 5 }, (_, i) => (
                                    <Select.Option key={currentYear - i} value={currentYear - i}>
                                        {currentYear - i}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            {actionType === 'generate' && (
                                <Button
                                    type="primary"
                                    onClick={handleGenerateSalary}
                                    loading={isGenerating}
                                    disabled={!month || !year}
                                    className="mr-4"
                                >
                                    Generate Salary
                                </Button>
                            )}
                            {actionType === 'get' && !isFetching && fetchedSalaryData?.length > 0 && (
                                <Button
                                    type="primary"
                                    onClick={() => setSalaryData(fetchedSalaryData || [])}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Get Salary
                                </Button>
                            )}
                            {actionType === 'get' && !isFetching && salaryData.length > 0 && (
                                <>
                                    <Button
                                        type="primary"
                                        onClick={handleDownloadPDF2}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download PDF
                                    </Button>


                                    <Button
                                        type="primary"
                                        onClick={handleDownloadExcel}
                                        disabled={salaryData.length === 0}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download Excel
                                    </Button>
                                </>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <Spin spinning={isFetching || loading}>
                <Table
                    columns={columns}
                    dataSource={salaryData}
                    rowKey="userName"
                    pagination={false}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                />
            </Spin>
        </div>
    );
};

export default SalaryReport;
