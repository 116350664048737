// Utility functions for localStorage operations

/**
 * Save data to localStorage.
 * @param {string} name - The key under which the data will be stored.
 * @param {object} jsonObject - The JSON object to be stored.
 */
export const saveToLocalStorage = (name: string, jsonObject: object): void => {
    try {
      const jsonString = JSON.stringify(jsonObject);
      localStorage.setItem(name, jsonString);
    } catch (error) {
      console.error("Error saving to localStorage", error);
    }
  };
  
  /**
   * Get data from localStorage.
   * @param {string} name - The key under which the data is stored.
   * @returns {object | null} - The parsed JSON object, or null if not found.
   */
  export const getFromLocalStorage = (name: string): object | null => {
    try {
      const jsonString = localStorage.getItem(name);
      return jsonString ? JSON.parse(jsonString) : null;
    } catch (error) {
      console.error("Error reading from localStorage", error);
      return null;
    }
  };
  