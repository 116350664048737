import React, { useState, useEffect } from "react";
import { Table, Button, message, Spin, Row, Divider, Form, Modal, Input, Tooltip, notification, Col, DatePicker, Select } from "antd";
import { EditOutlined, DeleteOutlined, NotificationTwoTone, FlagTwoTone } from "@ant-design/icons";
import { format, subDays } from "date-fns";
import { HolidayModal } from "./holidaymodal";
import { AnnouncementFormData, Holiday, LeaveBalance } from "../../types";
import Title from "antd/es/typography/Title";
import { ColumnType } from 'antd/es/table'
import { AnnouncementModal } from "../Announcement/Announcementmodal";
import { generateSaalary } from "../../../services/salaryApi's/salary";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import moment from "moment";
import { useAddHolidaysMutation, useAddLeaveCreditsMutation, useCreateAnnouncementMutation, useDeleteHolidayMutation, useGetHolidaysQuery, useGetLeaveCreditsQuery, useUpdateHolidaysMutation, useUpdateHolidaysStatusMutation, useUpdateUsersCreditsMutation } from "../../../api";
interface SalaryRecord {
    userName: string;
    presentDays: number;
    absentDays: number;
    totalSalary: number;
    Usersalary: string;
}

const Calender = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [holiday, setHoliday] = useState<Holiday | null>(null);
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [leaveCreditsModalOpen, setLeaveCreditsModalOpen] = useState(false);
    const [announcementProps, setAnnouncementProps] = useState<any>(null);
    // const [leaveBalance, setLeaveBalance] = useState(null);
    const [leaveBalance, setLeaveBalance] = useState<LeaveBalance[]>([]);
    const [form] = Form.useForm();
    const [formHoliday] = Form.useForm();
    const [formAnnouncement] = Form.useForm();
    const [formASalary] = Form.useForm();
    // const [loading, setLoading] = useState(false);
    const [salaryData, setSalaryData] = useState<SalaryRecord[]>([]);
    const { user: companyData } = useSelector((state: RootState) => state.auth);
    const { data: leaveCreditsData, isLoading, isError, refetch } = useGetLeaveCreditsQuery({ companyId: companyData?.id }, {
        skip: !companyData?.id,
    });
    const { data: getHolidayData, error: queryError, refetch: refetchData } = useGetHolidaysQuery(
        { companyId: companyData?.id },
        { skip: !companyData?.id }
    );
    const [addLeaveCredits] = useAddLeaveCreditsMutation()
    const [addHolidays] = useAddHolidaysMutation()
    const [updateHolidays] = useUpdateHolidaysMutation()
    const [deleteHoliday] = useDeleteHolidayMutation()
    const [updateHolidaysStatus] = useUpdateHolidaysStatusMutation()
    const [createAnnouncement] = useCreateAnnouncementMutation();
    const [updateUsersCredits] = useUpdateUsersCreditsMutation();
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
            if (isError) {
                message.error("Failed to fetch leave credits.");
            }
            if (leaveCreditsData?.leaveCredits && Array.isArray(leaveCreditsData.leaveCredits)) {
                setLeaveBalance(leaveCreditsData.leaveCredits);
            } else if (!leaveCreditsData?.leaveCredits) {
                setLeaveBalance([]);
            }
        }
    }, [isLoading, isError, leaveCreditsData]);

    useEffect(() => {
        if (getHolidayData && getHolidayData.holidays) {
            setHolidays(getHolidayData.holidays);
        } else if (queryError) {
            setError("Failed to fetch holiday data. Please try again later.");
        }
    }, [getHolidayData, queryError]);

    // Handle Edit Leave Credits
    const handleEditLeaveCredit = (record: LeaveBalance) => {
        form.setFieldsValue({
            annualLeaveBalance: record.credits,
        });

        setLeaveCreditsModalOpen(true);
    };

    // Handle Leave Credits Submit
    const handleLeaveCreditsSubmit = async (values: any) => {
        try {
            setLoading(true);
            const companyId = companyData?.id;
            const isEditing = leaveCreditsData?.leaveCredits && leaveCreditsData.leaveCredits.length > 0;

            const response = await addLeaveCredits({ companyId, annualLeaveBalance: values });
            if (isEditing) {
                message.success("Leave credits updated successfully.");
            } else {
                message.success("Leave credits added successfully.");
            }

            setLeaveCreditsModalOpen(false);
            form.resetFields();
            refetch();
        } catch (error) {
            console.error("Error adding leave credits:", error);
            message.error("Failed to add leave credits. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // Handle Edit Holiday
    const handleEdit = (record: Holiday) => {
        setHoliday(record);
        setIsModalOpen2(true);
        formHoliday.setFieldsValue({
            record,
        });
    };

    // Handle Delete Holiday
    const handleDelete = async (id: string) => {
        Modal.confirm({
            title: "Are you sure you want to delete this Public Holiday?",
            content: "This action cannot be undone.",
            onOk: async () => {
                try {
                    setLoading(true);
                    await deleteHoliday({ companyId: companyData?.id, id });
                    setHolidays(holidays.filter((holiday) => holiday._id !== id));
                    message.success("Holiday deleted successfully.");
                } catch (error) {
                    console.error("Error deleting holiday:", error);
                    message.error("Failed to delete holiday. Please try again.");
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    // Handle Holiday Submit
    const handleSubmit = async (values: any) => {
        try {
            setLoading(true);
            if (holiday) {
                const updatedHoliday = {
                    id: holiday._id,
                    ...values,
                };
                await updateHolidays({ companyId: companyData?.id, updatedHoliday });
                message.success("Holiday updated successfully.");
            } else {
                await addHolidays({ companyId: companyData?.id, holidayData: values });
                message.success("Holiday created successfully.");
            }
            closeModal();
            refetchData();
        } catch (error) {
            console.error("Error submitting holiday:", error);
            message.error("Failed to save holiday. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // Close Modal
    const closeModal = () => {
        setIsModalOpen2(false);
        setHoliday(null);
        formHoliday.resetFields();
    };

    // Handle Announcement Click
    const handleAnnouncementClick = (holiday: Holiday) => {
        setAnnouncementProps({
            id: holiday._id,
            title: holiday.title,
            startDate: holiday.startDate,
            endDate: holiday.endDate,
        });
        setIsModalOpen(true);
    };

    // useEffect(() => {
    //     if (isLoading) {
    //         setLoading(true);
    //     } else {
    //         setLoading(false);
    //         if (isError) {
    //             message.error("Failed to fetch leave credits.");
    //         }

    //         if (leaveCreditsData?.leaveCredits && Array.isArray(leaveCreditsData.leaveCredits)) {
    //             setLeaveBalance(leaveCreditsData.leaveCredits);
    //         } else if (!leaveCreditsData?.leaveCredits) {
    //             setLeaveBalance([]);
    //         }
    //     }
    // }, [isLoading, isError, leaveCreditsData]);
    // const handleEditLeaveCredit = (record: LeaveBalance) => {
    //     form.setFieldsValue({
    //         annualLeaveBalance: record.credits,
    //     });

    //     setLeaveCreditsModalOpen(true);
    // };
    // const handleLeaveCreditsSubmit = async (values: any) => {
    //     try {
    //         setLoading(true);
    //         const companyId = companyData?.id;
    //         const isEditing = leaveCreditsData?.leaveCredits && leaveCreditsData.leaveCredits.length > 0;


    //         const response = await addLeaveCredits({ companyId, annualLeaveBalance: values });
    //         if (isEditing) {
    //             message.success("Leave credits updated successfully.");
    //         } else {
    //             message.success("Leave credits added successfully.");
    //         }

    //         setLeaveCreditsModalOpen(false);
    //         form.resetFields();
    //         refetch();

    //     } catch (error) {
    //         console.error("Error adding leave credits:", error);
    //         message.error("Failed to add leave credits. Please try again.");
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // useEffect(() => {
    //     if (getHolidayData && getHolidayData.holidays) {
    //         setHolidays(getHolidayData.holidays);
    //     } else if (queryError) {
    //         setError("Failed to fetch holiday data. Please try again later.");
    //     }
    // }, [getHolidayData, queryError]);

    // const handleEdit = (record: Holiday) => {
    //     setHoliday(record);
    //     setIsModalOpen2(true);
    //     formHoliday.setFieldsValue({
    //         record
    //     });
    // };

    // const handleDelete = async (id: string) => {
    //     Modal.confirm({
    //         title: "Are you sure you want to delete this Public Holiday?",
    //         content: "This action cannot be undone.",
    //         onOk: async () => {
    //             try {
    //                 setLoading(true);
    //                 await deleteHoliday({companyId: companyData?.id, id});
    //                 setHolidays(holidays.filter((holiday) => holiday._id !== id));
    //                 message.success("Holiday deleted successfully.");
    //             } catch (error) {
    //                 console.error("Error deleting holiday:", error);
    //                 message.error("Failed to delete holiday. Please try again.");
    //             } finally {
    //                 setLoading(false);
    //             }
    //         },
    //     });
    // };
    // const handleSubmit = async (values: any,) => {
    //     try {
    //         setLoading(true);
    //         if (holiday) {
    //             const updatedHoliday = {
    //                 id: holiday._id,
    //                 ...values
    //             };
    //             await updateHolidays({companyId: companyData?.id ,  updatedHoliday});
    //             message.success("Holiday updated successfully.");
    //         } else {

    //             await addHolidays({companyId:companyData?.id ,holidayData: values});
    //             message.success("Holiday created successfully.");

    //         }
    //         closeModal();
    //         refetchData();
    //     } catch (error) {
    //         console.error("Error submitting holiday:", error);
    //         message.error("Failed to save holiday. Please try again.");
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // const closeModal = () => {
    //     setIsModalOpen2(false);
    //     setHoliday(null);
    //     formHoliday.resetFields();
    // };
    // const handleAnnouncementClick = (holiday: Holiday) => {
    //     setAnnouncementProps({
    //         id: holiday._id,
    //         title: holiday.title,
    //         startDate: holiday.startDate,
    //         endDate: holiday.endDate,
    //     });
    //     setIsModalOpen(true);
    // };
    const columns1: ColumnType<Holiday>[] = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: 'center',

        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            align: 'center',
            render: (date: string) => (date ? format(new Date(date), "PPP") : "N/A"),
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            align: 'center',
            render: (date: string) => {
                if (!date) return "N/A";
                const adjustedDate = subDays(new Date(date), 1);
                return format(adjustedDate, "PPP");
            },
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            render: (record: Holiday) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ marginRight: 8, }}>
                        <Tooltip title="Edit">

                            <EditOutlined
                                style={{ fontSize: 20, cursor: "pointer" }}
                                onClick={() => handleEdit(record)}
                            />
                        </Tooltip>
                    </span>
                    <span style={{ marginRight: 8 }}>
                        <Tooltip title="Delete">
                            <DeleteOutlined
                                style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                                onClick={() => handleDelete(record._id)}
                            />
                        </Tooltip>
                    </span>
                    {record.announcementCreated ? (
                        <span>
                            <Tooltip title="Announcement Created">
                                <FlagTwoTone
                                    style={{ fontSize: 20, cursor: "default" }}

                                />
                            </Tooltip>
                        </span>
                    ) : (
                        <span>

                            <Tooltip title="Make Announcement ">
                                <NotificationTwoTone
                                    style={{ fontSize: 20, cursor: "pointer" }}

                                    onClick={() => handleAnnouncementClick(record)}
                                />
                            </Tooltip>
                        </span>
                    )}
                </div>
            ),
        },
    ];

    const columns2: ColumnType<LeaveBalance>[] = [
        {
            title: "Annual Leave Credits",
            dataIndex: "credits",
            key: "credits",
            align: 'center',
            render: (credits: number) => credits !== undefined && credits !== null ? credits : "N/A",
        },
        {
            title: "Monthly Leave Credits",
            key: "perMonthCredits",
            align: 'center',
            render: (record: LeaveBalance) => {
                const credits = Number(record.credits);
                const perMonth = !isNaN(credits) ? credits / 12 : 0;
                return perMonth ? perMonth.toFixed(2) : "N/A";
            },
        },
        {
            title: "Created",
            dataIndex: "createdAt",
            key: "createdAt",
            align: 'center',
            render: (date: string) => {
                if (!date) return "N/A";
                const adjustedDate = subDays(new Date(date), 1);
                return format(adjustedDate, "PPP");
            },
        },
        {
            title: "Updated",
            dataIndex: "updatedAt",
            key: "updatedAt",
            align: 'center',
            render: (date: string) => {
                if (!date) return "N/A";
                const adjustedDate = subDays(new Date(date), 1);
                return format(adjustedDate, "PPP");
            },
        },
        {
            title: "Actions",
            key: "action",
            align: 'center',
            render: (record: LeaveBalance) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {leaveBalance.length > 0 && (
                        <Tooltip title="Edit">
                            <EditOutlined
                                style={{ fontSize: 20, cursor: "pointer" }}
                                onClick={() => handleEditLeaveCredit(record)}
                            />
                        </Tooltip>
                    )}
                </div>
            ),
        },



    ];

    // const handleLeaveCreditsSubmit = async (values: any) => {
    //     try {
    //         setLoading(true);
    //         const companyId = companyData?.id;
    //         const response = await addLeaveCredits({ companyId, annualLeaveBalance: values })
    //         message.success("Leave credits added successfully.");
    //         setLeaveCreditsModalOpen(false);
    //         form.resetFields();
    //         // getLeaveData();'
    //     } catch (error) {
    //         console.error("Error adding leave credits:", error);
    //         message.error("Failed to add leave credits. Please try again.");
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    const onFinish = async (values: AnnouncementFormData, fileList: any[],) => {
        setLoading(true);

        try {
            const imageUrls = fileList.map((file) => file.url || file.response?.uploads[0]?.url);

            const announcementData: AnnouncementFormData = {
                ...values,
                imageUrls,
            };
            const holiday = announcementProps;
            const id = holiday?.id;
            const announcementCreated = true;
            await createAnnouncement({ companyId: companyData?.id, announcementData });
            await updateHolidaysStatus({ companyId: companyData?.id, id, announcementCreated })
            setHolidays(prevHolidays =>
                prevHolidays.map(h =>
                    h._id === id ? { ...h, announcementCreated: true } : h
                )
            );

            setIsModalOpen(false);
            notification.success({
                message: "Success",
                description: "The Announcement has been created successfully.😉",
            });
        } catch (error) {
            console.error("Error creating announcement:", error);
            notification.error({
                message: "Error",
                description: "Failed to create the announcement. Please try again.",
            });
        } finally {
            setLoading(false);
        }
    };


    const onFinishFailed = (errorInfo: any) => { };
    if (loading) {
        return (
            <Row justify="center" align="middle" style={{ height: "100vh" }}>
                <Col>
                    <Spin size="large" />
                </Col>
            </Row>
        );
    }


    const handleGenerateSalary = async (values: any) => {
        setLoading(true);
        try {
            // Prepare data
            const { startDate, endDate, month, year } = values;
            const data = { startDate, endDate, month, year };

            const result = await generateSaalary(data);
            setSalaryData(result.data);
            message.success(result.message);
        } catch (error) {
            message.error('Error generating salary report');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF();

        // Set title
        doc.setFontSize(18);
        doc.text('Salary Report', 14, 20);

        // Add table
        const tableData = salaryData.map((item) => [
            item.userName,
            item.presentDays,
            item.absentDays,
            item.totalSalary,
            item.Usersalary,
        ]);
        const tableColumns = ['User Name', 'Present Days', 'Absent Days', 'Total Salary', 'User Salary'];

        doc.autoTable({
            head: [tableColumns],
            body: tableData,
            startY: 30,
        });

        // Save PDF
        doc.save('salary_report.pdf');
    };

    const columns = [
        { title: 'User Name', dataIndex: 'userName', key: 'userName' },
        { title: 'Present Days', dataIndex: 'presentDays', key: 'presentDays' },
        { title: 'Absent Days', dataIndex: 'absentDays', key: 'absentDays' },
        { title: 'Total Salary', dataIndex: 'totalSalary', key: 'totalSalary' },
        { title: 'User Salary', dataIndex: 'Usersalary', key: 'Usersalary' },
    ];
    const UsersCredits = async () => {
        try {
            const response: any = await updateUsersCredits({ companyId: companyData?.id }).unwrap();

            if (response && response?.message) {
                if (response?.message === 'Leave credits have been updated for this Month.') {
                    message.info(response?.message);
                } else {
                    message.success("Leave credits updated successfully!");
                }
            } else {
                message.error("Error updating leave credits!");
            }
        } catch (error) {
            console.error(error);
            message.error("An unexpected error occurred. Please try again later.");
        }
    };
    return (
        <div>
            <div className="flex justify-between items-center bg-slate-200 p-3 rounded-md w-full">
                <div className="flex items-center border-l-4 border-secondary-color pl-2 h-9">
                    <Title level={5} >
                        Annual Leave Credits
                    </Title>
                </div>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Button onClick={UsersCredits}>
                            Update Leaves Credits
                        </Button>
                    </Col>

                    <Col>
                        <Button type="primary" onClick={() => setLeaveCreditsModalOpen(true)}
                            style={{ display: leaveBalance && leaveBalance.length === 0 ? "inline-block" : "none" }}
                            className="ml-4">
                            Add Leave Credits
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table
                dataSource={leaveBalance || []}
                columns={columns2}
                rowKey="_id"
                loading={loading}
                tableLayout="fixed"
                style={{ marginTop: 16 }}
            />
            <Divider />
            <div className="flex justify-between items-center bg-slate-200 p-3 rounded-md w-full">
                <div className="flex items-center border-l-4 border-secondary-color pl-2 h-9">
                    <Title level={5}>
                        Public Holidays
                    </Title>
                </div>

                <Button type="primary" onClick={() => setIsModalOpen2(true)} className="ml-4">
                    Add Holiday
                </Button>
            </div>
            <Table
                dataSource={holidays}
                columns={columns1}
                rowKey="_id"
                loading={loading}
                tableLayout="fixed"
                style={{ marginTop: 16 }}
            />
            <HolidayModal
                isModalOpen={isModalOpen1}
                handleCancel={closeModal}
                loading={loading}
                onFinish={handleSubmit}
                onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
                form={formHoliday}
                holiday={holiday}
            />
            <Modal
                title={leaveBalance.length === 0 ? "Add Leave Credits" : "Edit Leave Credits"}
                visible={leaveCreditsModalOpen}
                onCancel={() => setLeaveCreditsModalOpen(false)}
                onOk={() => form.submit()}
                confirmLoading={loading}
                className="!w-[40%]"
            >
                <Form form={form} onFinish={handleLeaveCreditsSubmit}>
                    <Form.Item
                        label="Annual Leave Credits"
                        name="annualLeaveBalance"
                        rules={[
                            { required: true, message: 'Please enter the annual leave balance' },
                            {
                                pattern: /^[0-9]{1,2}$/,
                                message: 'Please enter a valid number (0-99)',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Leave Balance"
                            maxLength={2}
                            style={{ width: '50%' }}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <AnnouncementModal
                isModalOpen={isModalOpen}
                handleCancel={() => setIsModalOpen(false)}
                loading={loading}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={formAnnouncement}
                announcement={announcementProps}
            />
        </div>


    );
};

export default Calender;
